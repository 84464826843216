import { ArrowBack, InfoOutlined } from "@mui/icons-material";
import {
  Avatar,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { daysOld, formatInDateTime } from "../../../helper/time";
import Button from "../../atoms/button";
import CustomStatus from "../../atoms/customStatus";
import DialogModal from "../../atoms/dialog";

import { useCustomerProfile } from "../../../pages/customerProfile/customerProfile.context";
import { RevokedAccount, SuspendAccount } from "./customerCardModal";
import styles from "./customerInfoCard.module.scss";
type props = {
  badgeList: any[];
};

// type accountApprovalStatus = "WAITING_FOR_APPROVAL" | "APPROVED" | "REJECTED" | "SUSPENDED";

type modalName = "" | "suspend" | "reject" | "approve" | "revoked";

const CustomerInfoCard: React.FC<props> = ({ badgeList }) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState<modalName>("");
  const { customerInfo, industrieList } = useCustomerProfile();
  const [accountApprovalStatus, setAccountApprovalStatus] = useState(
    "WAITING_FOR_APPROVAL"
  );
  const customerProfile = customerInfo?.customerProfile || {};
  const { customerId = "" } = useParams();
  const joinedDate = formatInDateTime({
    date: customerInfo?.createdAt,
    fullMonth: true,
    timeHours: false,
  });
  const lastLoggedInDate = formatInDateTime({
    date: customerInfo?.lastLoginOn,
    fullMonth: true,
  });
  const lastActivityDate = formatInDateTime({
    date: customerInfo.lastActivityOn,
    fullMonth: true,
  });
  const subscriptionPlan = customerInfo.subscriptionPlan || {};

  const _accountApprovalStatus: string = `${customerProfile?.accountApprovalStatus}`;
  // const cvUrl = customerProfile?.cvUrl || "";
  const isUserActive = daysOld(lastLoggedInDate) < 10;

  const industryId = customerInfo.industryId;
  const industrie = useMemo(() => {
    return industrieList.find((item: any) => item.id === industryId) || {};
  }, [industrieList, industryId]);

  useEffect(() => {
    _accountApprovalStatus && setAccountApprovalStatus(_accountApprovalStatus);
  }, [_accountApprovalStatus]);

  const handleBackClick = () => {
    if (customerInfo?.role === "CUSTOMER") navigate("/customers");
    else navigate(`/enterprise/${customerProfile?.enterpriseProfileId}`);
  };

  return (
    <>
      <Stack direction="row" alignItems={"center"} spacing={2} mb={2.5}>
        <IconButton
          aria-label="back"
          color={"inherit"}
          onClick={handleBackClick}
        >
          <ArrowBack sx={{ width: "20px", height: "20px" }} />
        </IconButton>

        <Typography variant="h6" sx={{ whiteSpace: "nowrap" }}>
          {customerInfo.name}
        </Typography>

        <div className="d-flex align-items-center">
          <CustomStatus
            status={
              accountApprovalStatus === "SUSPENDED" ||
              accountApprovalStatus === "REJECTED"
                ? "error"
                : isUserActive
                ? "approved"
                : "pending"
            }
            message={
              accountApprovalStatus === "ACTIVE"
                ? isUserActive
                  ? "ACTIVE"
                  : "INACTIVE"
                : accountApprovalStatus
            }
          />
          {accountApprovalStatus === "ACTIVE" && (
            <Tooltip
              title={
                "If Customer have logged in last 10 days then it will display as active otherwise inactive."
              }
            >
              <InfoOutlined
                className="info_icon ms-2"
                sx={{ color: "#ADAAB5" }}
              />
            </Tooltip>
          )}
        </div>

        <div className="col d-flex align-item-center justify-content-end">
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <div style={{ display: "inline-flex", gap: "12px" }}>
              {accountApprovalStatus !== "SUSPENDED" && (
                <Button
                  className={`${styles.error}`}
                  variant="error"
                  onClick={() => {
                    setShowModal("suspend");
                  }}
                >
                  Suspend
                </Button>
              )}
            </div>
            {accountApprovalStatus === "SUSPENDED" && (
              <Button
                className={` ${styles.activeRevoke}`}
                variant="success"
                onClick={() => {
                  setShowModal("revoked");
                }}
              >
                Restore user
              </Button>
            )}
          </div>
        </div>
      </Stack>

      <div className={styles.userInfoWrapper}>
        <Grid container columnSpacing={5}>
          <Grid item xs="auto" className="avatarGrid">
            <Avatar
              alt={customerInfo.name}
              src={customerInfo.avatarUrl}
              sx={{ width: "6.5rem", height: "6.5rem", mt: "1rem" }}
            />
            {/* {cvUrl && (
              <Button type="link" href={cvUrl} target="_blank" variant="light" className="mt-4">
                Download CV <DownloadIcon />
              </Button>
            )} */}
          </Grid>

          <Grid item xs>
            <List>
              <ListItem className={styles.divider}>
                <Typography className={styles.profileLabel}> Email </Typography>
                <ListItemText
                  className={styles.rightText}
                  primary={customerInfo.email}
                />
              </ListItem>
              <ListItem className={styles.divider}>
                <Typography className={styles.profileLabel}>
                  Industry
                </Typography>
                <ListItemText
                  className={styles.rightText}
                  primary={industrie.name}
                />
              </ListItem>
              <ListItem className={styles.divider}>
                <Typography className={styles.profileLabel}>Plan</Typography>
                <ListItemText
                  className={styles.rightText}
                  primary={subscriptionPlan.name}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md>
            <List>
              <ListItem className={styles.divider}>
                <Typography className={styles.profileLabel}>Joined</Typography>
                <ListItemText
                  className={styles.rightText}
                  primary={joinedDate}
                />
              </ListItem>
              <ListItem className={styles.divider}>
                <Typography className={styles.profileLabel}>
                  Last logged in
                </Typography>
                <ListItemText
                  className={styles.rightText}
                  primary={lastLoggedInDate || "NA"}
                />
              </ListItem>
              <ListItem className={styles.divider}>
                <Typography className={styles.profileLabel}>
                  Last activity
                </Typography>
                <ListItemText
                  className={styles.rightText}
                  primary={lastActivityDate || "NA"}
                />
              </ListItem>
              <ListItem>
                <Typography className={styles.profileLabel}>
                  Total revenue
                </Typography>
                <ListItemText
                  className={styles.rightText}
                  primary={`${customerInfo?.totalRevenue || 0}   USD`}
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </div>

      {showModal === "suspend" && (
        <DialogModal
          openModal={!!showModal}
          setOpenModal={() => setShowModal("")}
          heading={"Suspend user"}
          body={
            <SuspendAccount
              id={customerId}
              onSuccess={() => setAccountApprovalStatus("SUSPENDED")}
              setOpenModal={() => setShowModal("")}
            />
          }
        />
      )}

      {showModal === "revoked" && (
        <DialogModal
          openModal={!!showModal}
          setOpenModal={() => setShowModal("")}
          heading={"Revoke suspension"}
          body={
            <RevokedAccount
              id={customerId}
              onSuccess={() => setAccountApprovalStatus("ACTIVE")}
              setOpenModal={() => setShowModal("")}
            />
          }
        />
      )}
    </>
  );
};
export default CustomerInfoCard;
