import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useLocation } from "react-router-dom";
import { InboxIcon } from "../../components/atoms/icons";
import Loader from "../../components/atoms/loader";
import SmeListingHeader from "../../components/organisms/expertListingHeader";
import SmeListingTable from "../../components/organisms/expertListingTable";
import Header from "../../components/organisms/header";
import { fetchSmeList } from "../../services/smeListing";
import styles from "./smeListing.module.scss";

const SmeListingPage = () => {
  const [expertList, setExpertList] = useState([]);
  const [expertCount, setExpertCount] = useState();
  const [allExpertData, setAllExpertData] = useState([]);
  const getParams = useLocation().search || "?limit=10";

  useEffect(() => {
    console.log("getParams:", getParams);
    
    getParams &&
      fetchSmeList(getParams)
        ?.then((res) => {
          setExpertList(res?.data?.data?.smeUsersData);
          setExpertCount(res?.data?.data?.totalSMECount);
        })
        .catch((err) => console.log(err));
  }, [getParams]);

  // const handleExportData = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_API_BASE_URL}admin/sme/export`,
  //       {
  //         responseType: "blob",
  //         headers: {
  //           Authorization: `Bearer ${accessToken}`,
  //           "Content-Type": "text/csv, charset=utf-8",
  //         },
  //       }
  //     );
  //     if (response.status === 200) {
  //       const data = response.data;
  //       downloadExcelFile(data, "exported-data.xlsx");
  //       console.log(data);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  useEffect(() => {
    fetchSmeList()
      ?.then((res) => {
        setAllExpertData(res?.data?.data?.smeUsersData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="mainWrapper">
      {/* header */}
      <Header />

      <section className="contentWrapper">
        <div className={styles.exportData}>
          {allExpertData?.length > 0 && (
            <button>
              <CSVLink data={allExpertData} filename="expert-list.csv">
                Export data
              </CSVLink>
            </button>
          )}
        </div>
        <section className={styles.smeListingWrapper}>
          <SmeListingHeader totalExperts={expertCount} />
          <section className="tableWrapper">
            {expertList ? (
              expertList?.length > 0 ? (
                <SmeListingTable listing={expertList} />
              ) : (
                <div className="noData">
                  <InboxIcon />
                  <p>No SME's yet</p>
                </div>
              )
            ) : (
              <Loader />
            )}
          </section>
        </section>
      </section>
    </div>
  );
};

export default SmeListingPage;
