import { Box, Tabs as MuiTabs, Tab } from "@mui/material";
import { ReactNode, SyntheticEvent, useState } from "react";
import style from "./tabPanel.module.scss";

type panel = {
  tabLabel: string;
  tabContent: ReactNode;
};

type props = {
  panels: panel[];
};

type TabPanelProps = {
  children?: ReactNode;
  index: number;
  value: number;
};

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

function TabContent(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </Box>
  );
}

const TabPanel: React.FC<props> = ({ panels }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <MuiTabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        textColor="inherit"
        className={style.customTabsPanel}
      >
        {panels.map(({ tabLabel }, i) => {
          return (
            <Tab
              className={style.tabBtn}
              label={tabLabel}
              {...a11yProps(i)}
              key={i}
            />
          );
        })}
      </MuiTabs>
      {panels.map(({ tabContent }, i) => {
        return (
          <TabContent index={i} value={value} key={i}>
            {tabContent}
          </TabContent>
        );
      })}
    </>
  );
};
export default TabPanel;
