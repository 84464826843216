export default function PlusIcon(Props: any) {
  return (
    <svg
      className={Props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0002 3.3335C10.4604 3.3335 10.8335 3.70659 10.8335 4.16683V15.8335C10.8335 16.2937 10.4604 16.6668 10.0002 16.6668C9.53993 16.6668 9.16683 16.2937 9.16683 15.8335V4.16683C9.16683 3.70659 9.53993 3.3335 10.0002 3.3335Z"
        fill="#E0DFE3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.3335 10.0002C3.3335 9.53993 3.70659 9.16683 4.16683 9.16683H15.8335C16.2937 9.16683 16.6668 9.53993 16.6668 10.0002C16.6668 10.4604 16.2937 10.8335 15.8335 10.8335H4.16683C3.70659 10.8335 3.3335 10.4604 3.3335 10.0002Z"
        fill="#E0DFE3"
      />
    </svg>
  );
}
