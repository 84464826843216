export default function CalendarIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="calendar">
        <path
          id="icon"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.66675 0.833496C7.12699 0.833496 7.50008 1.20659 7.50008 1.66683V2.50016H12.5001V1.66683C12.5001 1.20659 12.8732 0.833496 13.3334 0.833496C13.7937 0.833496 14.1667 1.20659 14.1667 1.66683V2.50016H15.8334C17.2141 2.50016 18.3334 3.61945 18.3334 5.00016V16.6668C18.3334 18.0475 17.2141 19.1668 15.8334 19.1668H4.16675C2.78604 19.1668 1.66675 18.0475 1.66675 16.6668V5.00016C1.66675 3.61945 2.78604 2.50016 4.16675 2.50016H5.83342V1.66683C5.83342 1.20659 6.20651 0.833496 6.66675 0.833496ZM5.83342 4.16683H4.16675C3.70651 4.16683 3.33341 4.53993 3.33341 5.00016V7.50016H16.6667V5.00016C16.6667 4.53992 16.2937 4.16683 15.8334 4.16683H14.1667V5.00016C14.1667 5.4604 13.7937 5.8335 13.3334 5.8335C12.8732 5.8335 12.5001 5.4604 12.5001 5.00016V4.16683H7.50008V5.00016C7.50008 5.4604 7.12699 5.8335 6.66675 5.8335C6.20651 5.8335 5.83342 5.4604 5.83342 5.00016V4.16683ZM16.6667 9.16683H3.33341V16.6668C3.33341 17.1271 3.70651 17.5002 4.16675 17.5002H15.8334C16.2937 17.5002 16.6667 17.1271 16.6667 16.6668V9.16683Z"
          fill="#E0DFE3"
        />
      </g>
    </svg>
  );
}
