import { HTTP_METHODS } from "../common/constants";
import { toQueryString } from "../helper/utility";
import { makeNetworkCall } from "./network";

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}admin`;

export const fetchSmeList = (query?: string) => {
  let url;
  query ? (url = `${baseUrl}/sme${query}`) : (url = `${baseUrl}/sme`);
  return makeNetworkCall(HTTP_METHODS.GET, url);
};

export const exportSmeList = () => {
  let url = `${baseUrl}/sme/export`;
  return makeNetworkCall(HTTP_METHODS.GET, url);
};

export const fetchBadge = () => {
  let url = `${baseUrl}/badge`;
  return makeNetworkCall(HTTP_METHODS.GET, url);
};
export const fetchSmeUser = (id: string) => {
  let url = `${baseUrl}/sme/profile/${id}`;
  return makeNetworkCall(HTTP_METHODS.GET, url);
};

export const fetchSmeInteraction = (id: string) => {
  let url = `${baseUrl}/sme/interaction/${id}`;
  return makeNetworkCall(HTTP_METHODS.GET, url);
};

export const fetchSmeActivityLog = (payload: any = {}) => {
  const date = new Date();
  console.log("date", date);
  
  let params = { skip: 0, limit: 10, ...payload, endTime: date.toISOString() };
  params = toQueryString(params);
  let url = `${baseUrl}/sme/activity/log?${params}`;
  return makeNetworkCall(HTTP_METHODS.GET, url);
};

export const fetchSmeEarningOutlook = (id:string|undefined) => {
  let url = `${baseUrl}/sme/${id}/payment/outlook`;
  return makeNetworkCall(HTTP_METHODS.GET, url);
};

export const fetchSmeEarningStats = (id:string|undefined , query?:string) => {
  let url = `${baseUrl}/sme/${id}/payment/statistics?${query}`;
  return makeNetworkCall(HTTP_METHODS.GET, url);
};

export const smeProfileApprove = (id:string) => {
  let url = `${baseUrl}/sme/profile/${id}/approve`;
  return makeNetworkCall(HTTP_METHODS.PATCH, url);
}
export const smeProfileReject = (id:string) => {
  let url = `${baseUrl}/sme/profile/${id}/reject`;
  return makeNetworkCall(HTTP_METHODS.PATCH, url);
}

export const smeProfileSuspend = (id:string) => {
  let url = `${baseUrl}/sme/profile/${id}/suspend`;
  return makeNetworkCall(HTTP_METHODS.PATCH, url);
}

export const smeProfileRevokeSuspension = (id:string) => {
  let url = `${baseUrl}/sme/profile/${id}/reactivate`;
  return makeNetworkCall(HTTP_METHODS.PATCH, url);
}

export const smeProfileAddBadge = (payload: {smeProfileId: string, badgeId: string}) => {
  let url = `${baseUrl}/sme/profile/badge`;
  return makeNetworkCall(HTTP_METHODS.POST, url, payload);
}

export const smeProfileAddRecommendation = (payload: {smeProfileId: string, description: string}) => {
  const {smeProfileId, ...body} = payload;
  let url = `${baseUrl}/sme/profile/recommendation/${smeProfileId}`;
  return makeNetworkCall(HTTP_METHODS.POST, url, body);
}

export const smeProfileSummerization = (id:string) => {
  let url = `${baseUrl}/sme/manual_profile_summerization/${id}`;
  return makeNetworkCall(HTTP_METHODS.PATCH, url);
}