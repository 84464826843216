export default function SuspendIcon() {
  return (
    <svg
      height="20"
      id="SVGRoot"
      version="1.1"
      viewBox="0 0 16 16"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M 8.125 1.1875 A 6.9375 6.9375 0 0 0 1.1875 8.125 A 6.9375 6.9375 0 0 0 8.125 15.0625 A 6.9375 6.9375 0 0 0 15.0625 8.125 A 6.9375 6.9375 0 0 0 8.125 1.1875 z M 8.125 2.28125 A 5.84375 5.84375 0 0 1 11.751953 3.5527344 C 11.427586 3.4455184 11.065417 3.5170367 10.804688 3.8007812 L 4.0976562 11.097656 C 3.8494928 11.367726 3.7757607 11.745793 3.84375 12.095703 A 5.84375 5.84375 0 0 1 2.28125 8.125 A 5.84375 5.84375 0 0 1 8.125 2.28125 z M 12.296875 4.0351562 A 5.84375 5.84375 0 0 1 13.96875 8.125 A 5.84375 5.84375 0 0 1 8.125 13.96875 A 5.84375 5.84375 0 0 1 4.8105469 12.935547 C 5.0400983 12.924613 5.2669973 12.830774 5.4453125 12.636719 L 12.152344 5.3398438 C 12.477397 4.9860972 12.515123 4.45168 12.296875 4.0351562 z "
        id="path90"
        fill="#D86561"
      />
    </svg>
  );
}
