import { HTTP_METHODS } from "../common/constants";
import { makeNetworkCall } from "./network";

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}admin`;

export const getExpertCalendarDetails = (
  expertId: string,
  start: string,
  end: string
) => {
  let url = `${baseUrl}/sme/${expertId}/meeting/?startTime=${start}&endTime=${end}`;
  return makeNetworkCall(HTTP_METHODS.GET, url);
};

export const removeEvent = (eventId: string) => {
  let url = `${baseUrl}/sme/${eventId}/meeting?sendUnpaidScheduleCallEmail=false`;
  return makeNetworkCall(HTTP_METHODS.DELETE, url);
};

export const rescheduleEvent = (eventId: string, start: string) => {
  let url = `${baseUrl}/sme/${eventId}/meeting/?startTime=${start}`;
  return makeNetworkCall(HTTP_METHODS.PATCH, url);
};

export const getCustomerCalendarDetails = (
  customerId: string,
  start: string,
  end: string
) => {
  let url = `${baseUrl}/customer/${customerId}/meeting/?startTime=${start}&endTime=${end}`;
  return makeNetworkCall(HTTP_METHODS.GET, url);
};

export const cancelMeetingEvent = (eventId: string) => {
  let url = `${baseUrl}/meeting/${eventId}`;
  return makeNetworkCall(HTTP_METHODS.PATCH, url);
};

export const deleteMeetingEvent = (eventId: string) => {
  let url = `${baseUrl}/meeting/${eventId}`;
  return makeNetworkCall(HTTP_METHODS.DELETE, url);
};
