import { MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BadgeTypes } from "../../../../types/commontypes";
import { getIndustries } from "../../../services/authForms";
import { fetchBadge } from "../../../services/smeListing";
import Button from "../../atoms/button";
import CustomToggle from "../../atoms/customToggle";
import { CaretIcon, SearchIcon } from "../../atoms/icons";
import styles from "./smeListingHeader.module.scss";

// Custom hook for debouncing
function useDebounce(value: any, delay: any) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

interface industryProps {
  id: string;
  name: string;
}

const SmeListingHeader = ({ totalExperts }: any) => {
  const [type, setType] = useState("All");
  const [searchText, setSearchText] = useState<string>("");
  const [allIndustry, setAllIndustry] = useState<industryProps[]>([]);
  const [industry, setIndustry] = useState<string>("All");
  const [status, setStatus] = useState<string>("All");
  const [allBadge, setAllBadge] = useState<BadgeTypes[]>([]);
  const [sort, setSort] = useState<string>("All");
  const [resultsCount, setResultsCount] = useState<any>(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPage, setTotalPage] = useState<any>("1");
  const navigate = useNavigate();

  const toggleValues = [
    {
      id: "All",
      name: "All",
    },
    {
      id: "SME",
      name: "SMEs",
    },
    {
      id: "CONTRIBUTOR",
      name: "Contributors",
    },
  ];

  const debouncedSearchText = useDebounce(searchText, 500);

  function formatBadgeName(name: string) {
    // Split the name by camel case or underscore and join with spaces
    return name
      .split(/(?=[A-Z])|_/)
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(" ");
  }

  useEffect(() => {
    getIndustries()
      ?.then((res) => {
        setAllIndustry(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });

    fetchBadge()
      ?.then((res) => {
        setAllBadge(res?.data?.data?.badges);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const filterParams = `limit=${resultsCount}${
      pageNumber !== 1 ? `&skip=${resultsCount * (pageNumber - 1)}` : ""
    }${type !== "All" ? `&role=${type}` : ""}${
      industry !== "All" ? `&industryId=${industry}` : ""
    }${sort !== "All" ? `&sortBadgeSlug=${sort}` : ""}${
      status !== "All" ? `&status=${status}` : ""
    }${searchText.trim() !== "" ? `&search=${debouncedSearchText}` : ""}`;

    navigate({
      pathname: "",
      search: `?${filterParams}`,
    });
    setTotalPage(Math.ceil(totalExperts / resultsCount));
  }, [
    type,
    status,
    resultsCount,
    industry,
    sort,
    debouncedSearchText,
    totalExperts,
    pageNumber,
  ]);

  useEffect(() => {
    setPageNumber(1);
  }, [totalExperts, totalPage]);

  const handleTypeChange = (event: any) => {
    setType(event.target.value);
  };

  const handleSearchTextChange = (event: any) => {
    setSearchText(event.target.value);
  };

  const handleStatusChange = (event: any) => {
    setStatus(event.target.value);
  };

  const handleResultChange = (event: any) => {
    setResultsCount(event.target.value);
  };

  const handleIndustryChange = (event: any) => {
    setIndustry(event.target.value);
  };

  const handleSortChange = (event: any) => {
    setSort(event.target.value);
  };

  return (
    <section className={styles.smeListingHeader}>
      <div className={styles.smeListingFilter}>
        <div className={styles.filters}>
          <CustomToggle type={type} setType={setType} values={toggleValues} />

          <div
            className="input-row input-with-icon"
            style={{ marginBottom: 0 }}
          >
            <div className="input-icon">
              <SearchIcon />
              <input
                type="text"
                className="input-field"
                value={searchText}
                style={{ backgroundColor: "#2c2b33" }}
                placeholder="Search by name"
                onChange={handleSearchTextChange}
              />
            </div>
          </div>

          <Button variant="greyNoBorder">
            <Select
              labelId="earningSource"
              id="earningSource"
              value={industry}
              label="earningSource"
              IconComponent={CaretIcon}
              onChange={handleIndustryChange}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 350,
                    maxWidth: 300,
                  },
                },
              }}
            >
              <MenuItem value={"All"}>Industry</MenuItem>
              {allIndustry?.map((industry) => (
                <MenuItem
                  key={industry?.id}
                  id={industry?.id}
                  value={industry?.id}
                >
                  {industry?.name}
                </MenuItem>
              ))}
            </Select>
          </Button>

          <Button variant="greyNoBorder">
            <Select
              labelId="earningSource"
              id="earningSource"
              value={status}
              label="earningSource"
              IconComponent={CaretIcon}
              onChange={handleStatusChange}
            >
              <MenuItem value={"All"}>Status</MenuItem>
              <MenuItem value={"WAITING_FOR_APPROVAL"}>
                Waiting for Approval
              </MenuItem>
              <MenuItem value={"APPROVED"}>Approved</MenuItem>
              <MenuItem value={"REJECTED"}>Rejected</MenuItem>
              <MenuItem value={"SUSPENDED"}>Suspended</MenuItem>
            </Select>
          </Button>
        </div>

        <div className={styles.pagination}>
          <Button variant="greyNoBorder">
            Badges:
            <Select
              labelId="earningSource"
              id="earningSource"
              value={sort}
              label="earningSource"
              IconComponent={CaretIcon}
              onChange={handleSortChange}
            >
              {allBadge?.map(
                (badge) =>
                  badge?.status && (
                    <MenuItem
                      key={badge?.id}
                      id={badge?.id}
                      value={badge?.slug}
                    >
                      {formatBadgeName(badge?.name)}
                    </MenuItem>
                  )
              )}
            </Select>
          </Button>
          <Button variant="greyNoBorder">
            Results:
            <Select
              labelId="resultsCount"
              id="resultsCount"
              value={resultsCount}
              label="resultsCount"
              IconComponent={CaretIcon}
              onChange={handleResultChange}
            >
              <MenuItem value={10}>10 / Page</MenuItem>
              <MenuItem value={20}>20 / Page</MenuItem>
              <MenuItem value={30}>30 / Page</MenuItem>
            </Select>{" "}
          </Button>

          <Button variant="greyNoBorder" className={styles.paginationNav}>
            <small
              className={`${styles.prevPage} ${
                pageNumber === 1 && styles.disabled
              }`}
              onClick={() => {
                pageNumber > 1 && setPageNumber(pageNumber - 1);
              }}
            >
              <CaretIcon />
            </small>
            <span>{pageNumber}</span> / {totalPage || 1}
            <small
              className={`${styles.nextPage}  ${
                pageNumber === totalPage && styles.disabled
              }`}
              onClick={() => {
                pageNumber < totalPage && setPageNumber(pageNumber + 1);
              }}
            >
              <CaretIcon />
            </small>
          </Button>
        </div>
      </div>
    </section>
  );
};

export default SmeListingHeader;
