import AviaPayout from "../../components/molecules/aivaPayout";
import ConfigurationPlans from "../../components/molecules/configurationPlans";
import EviseCommission from "../../components/molecules/eviseCommission";
import ManualBadges from "../../components/molecules/manualBadges";
import Header from "../../components/organisms/header";
import styles from "./configuration.module.scss";

const Configuration = () => {
  return (
    <div className="mainWrapper">
      <Header />
      <section className="contentWrapper">
        <section className={styles.configurationBox}>
          <ConfigurationPlans />
        </section>
        <section className={styles.configurationBox}>
          <ManualBadges />
        </section>
        <section className={styles.configurationBox}>
          <AviaPayout />
        </section>
        <section className={styles.configurationBox}>
          <EviseCommission />
        </section>
      </section>
    </div>
  );
};

export default Configuration;
