import moment from "moment";

const DATE_FORMATS = {
  DEFAULT: "DD MMM YYYY",
};

type dateTimeProps = {
  date: Date | string;
  fullMonth?: boolean;
  timeHours?: 12 | 24 | false;
};

export function formatInDateTime({
  date,
  fullMonth = false,
  timeHours = 12,
}: dateTimeProps): string {
  if(!date){
    return "";
  }
  // Parse the date using Moment.js
  const getDate = moment(date);

  // Determine the time format based on timeHours
  let timeFormat = '';
  if (timeHours === 12) {
    timeFormat = 'hh:mm A';
  } else if (timeHours === 24) {
    timeFormat = 'HH:mm';
  }

  // Format the date
  const formatString = fullMonth
    ? `DD MMMM, YYYY ${timeFormat}`.trim()
    : `DD MMM, YYYY ${timeFormat}`.trim();
  const formattedDate = getDate.format(formatString);

  return formattedDate;
  // Outputs: "10 Aug, 2023 10:18 PM", "10 August, 2023 22:18", "10 Aug, 2023" or "10 August, 2023" depending on the fullMonth and timeHours parameters
}

export const dateFormatter = (value: any, format = DATE_FORMATS["DEFAULT"]) =>
  moment(value).format(format);

export function daysOld(dateString: string) {
  // Parse the date string using Moment.js
  var dateObject = moment(dateString, "D MMMM YYYY, h:mm A");

  // Get the current date
  var currentDate = moment();

  // Calculate the difference in days
  var differenceInDays = currentDate.diff(dateObject, "days");

  // Return the difference in days
  return differenceInDays;
}
