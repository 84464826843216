import React from "react";
export default function PiechartIcon() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="pie-chart" clipPath="url(#clip0_3334_299)">
        <path
          id="icon"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.3658 1.07757C10.5221 0.921293 10.7341 0.833496 10.9551 0.833496C12.1589 0.833496 13.3509 1.0706 14.463 1.53127C15.5752 1.99194 16.5857 2.66715 17.4369 3.51835C18.2881 4.36955 18.9633 5.38008 19.424 6.49223C19.8847 7.60438 20.1218 8.79638 20.1218 10.0002C20.1218 10.4604 19.7487 10.8335 19.2884 10.8335H10.9551C10.4949 10.8335 10.1218 10.4604 10.1218 10.0002V1.66683C10.1218 1.44582 10.2096 1.23385 10.3658 1.07757ZM11.7884 2.5466V9.16683H18.4087C18.3305 8.46801 18.1543 7.78224 17.8842 7.13004C17.5073 6.22009 16.9548 5.3933 16.2584 4.69686C15.562 4.00042 14.7352 3.44798 13.8252 3.07107C13.173 2.80091 12.4872 2.62473 11.7884 2.5466ZM8.38553 2.0252C8.56961 2.44702 8.37688 2.9382 7.95506 3.12227C6.83222 3.61226 5.8465 4.36985 5.08409 5.32879C4.32168 6.28774 3.80578 7.41886 3.5815 8.62325C3.35723 9.82764 3.4314 11.0686 3.79753 12.2377C4.16367 13.4068 4.81062 14.4685 5.68182 15.3298C6.55302 16.1911 7.62196 16.8259 8.79517 17.1786C9.96837 17.5314 11.2101 17.5914 12.4119 17.3534C13.6136 17.1153 14.7388 16.5866 15.689 15.8132C16.6391 15.0399 17.3854 14.0456 17.8626 12.9173C18.0418 12.4934 18.5307 12.295 18.9546 12.4743C19.3785 12.6535 19.5769 13.1425 19.3976 13.5664C18.8145 14.9455 17.9023 16.1607 16.741 17.1059C15.5797 18.0511 14.2045 18.6974 12.7357 18.9883C11.2669 19.2792 9.74918 19.2058 8.31526 18.7747C6.88134 18.3435 5.57486 17.5677 4.51006 16.515C3.44525 15.4623 2.65454 14.1647 2.20704 12.7358C1.75954 11.3069 1.66889 9.79016 1.943 8.31813C2.21712 6.8461 2.84766 5.46363 3.7795 4.29158C4.71134 3.11953 5.9161 2.19359 7.28846 1.59472C7.71028 1.41064 8.20146 1.60337 8.38553 2.0252Z"
          fill="#ADAAB5"
        />
      </g>
      <defs>
        <clipPath id="clip0_3334_299">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.955078)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
