import { MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../atoms/button";
import { CaretIcon } from "../../atoms/icons";
import styles from "./activityLog.module.scss";

type props = {
  totalLogs: number
}

const ActivityHeader: React.FC<props> = ({ totalLogs }) => {
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const [resultsCount, setResultsCount] = useState<any>(10);
  const [totalPage, setTotalPage] = useState<any>("1");

  const handleResultChange = (event: any) => {
    setResultsCount(event.target.value);
  };

  useEffect(() => {
    const filterParams = `limit=${resultsCount}${pageNumber !== 1 ? `&skip=${resultsCount * (pageNumber - 1)}` : ""}`;

    navigate({
      pathname: "",
      search: `?${filterParams}`,
    });
    setTotalPage(Math.ceil(totalLogs / resultsCount));

  }, [resultsCount, pageNumber, navigate, totalLogs]);


  return (
    <div className={styles.activityLogHeader}>
      <div className={styles.pagination}>
        <Button variant="greyNoBorder">
          Results:
          <Select
            labelId="resultsCount"
            id="resultsCount"
            value={resultsCount}
            label="resultsCount"
            IconComponent={CaretIcon}
            onChange={handleResultChange}
          >
            <MenuItem value={10}>10 / Page</MenuItem>
            <MenuItem value={20}>20 / Page</MenuItem>
            <MenuItem value={30}>30 / Page</MenuItem>
          </Select>{" "}
        </Button>

        <Button variant="greyNoBorder" className={styles.paginationNav}>
          <small
            className={`${styles.prevPage} ${pageNumber === 1 && styles.disabled}`}
            onClick={() => {
              pageNumber > 1 && setPageNumber(pageNumber - 1);
            }}
          >
            <CaretIcon />
          </small>
          <span>{pageNumber}</span> / {totalPage || 1}
          <small
            className={`${styles.nextPage} ${pageNumber === totalPage && styles.disabled}`}
            onClick={() => {
              pageNumber < totalPage && setPageNumber(pageNumber + 1);
            }}
          >
            <CaretIcon />
          </small>
        </Button>
      </div>
    </div>
  )
}
export default ActivityHeader