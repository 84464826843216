import { HTTP_METHODS } from "../common/constants";
import { makeNetworkCall } from "./network";

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}admin`;

export const getSubscriptionPlan = async () => {
  let url = `${baseUrl}/subscription-plan`;
  return makeNetworkCall(HTTP_METHODS.GET, url);
};

export const updateSubscriptionPlan = async (id: string, data: any) => {
  let url = `${baseUrl}/subscription-plan/${id}`;
  return makeNetworkCall(HTTP_METHODS.PATCH, url, data);
};
