import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ActivityHeader from "./activityHeader";

import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { fetchSmeActivityLog } from "../../../services/smeListing";

import { formatInDateTime } from "../../../helper/time";
import { fetchCustomerActivityLog } from "../../../services/customerListing";
import style from "./activityLog.module.scss";

type props = {
  infoType: "SME" | "CUSTOMER" | "ENTERPRISE"
};

const ActivityLog: React.FC<props> = ({ infoType }) => {
  const { expertId = "", customerId = "" } = useParams();
  const [searchParams] = useSearchParams();
  const skip = searchParams.get("skip");
  const limit = searchParams.get("limit");
  const [activityLogs, setActivityLogs] = useState([]);
  const [totalLogs, setTotalLogs] = useState(0);

  const onResponse = (res: any) => {
    const response: any = res?.data;
    if (response?.status === "success" && response?.data) {
      setTotalLogs(response.data?.totalLogs || 0);
      const logs = response.data?.activityLogs;
      if (logs.length > 0) {
        setActivityLogs(logs);
      }
    }
  }

  useEffect(() => {
    const payload = {
      skip: Number(skip) || 0,
      limit: Number(limit) || 10,
    }
    if (infoType === "SME") {
      fetchSmeActivityLog({ userId: expertId, ...payload })?.then(onResponse);
    } else if (infoType === "CUSTOMER") {
      fetchCustomerActivityLog({ userId: customerId, ...payload })?.then(onResponse);
    }
  }, [customerId, expertId, infoType, limit, skip]);
  return (
    <div className={style.activityLogWrapper}>
      <ActivityHeader totalLogs={totalLogs} />
      <div className="tableWrapper">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell width={250}>Date & time</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activityLogs.map((activity: any) => {
                var fileName = activity?.extraParams?.fileName || ''
                if (!!fileName) {
                  const underscoreIndex = fileName.indexOf('_');
                  fileName = `: ${fileName.substring(underscoreIndex + 1)}`;
                }
                return (
                  <TableRow
                    key={activity.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <p>
                        {formatInDateTime({
                          date: activity.updatedAt,
                          fullMonth: false,
                          timeHours: 24,
                        })}
                      </p>
                    </TableCell>
                    <TableCell>
                      <p>{`${activity.action}`.replaceAll("_", " ")} {fileName}</p>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {!activityLogs.length && (
            <big className={`noDataBigText ${style.noData} mt-5`}>
              No data available
            </big>
          )}
        </TableContainer>
      </div>
    </div>
  );
};
export default ActivityLog;
