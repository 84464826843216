export default function AudioIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49"
      height="49"
      viewBox="0 0 49 49"
      fill="none"
    >
      <g clipPath="url(#clip0_4871_1798)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.0931 3.09315C20.5934 1.59285 22.6283 0.75 24.75 0.75C26.8717 0.75 28.9066 1.59285 30.4069 3.09315C31.9071 4.59344 32.75 6.62827 32.75 8.75V24.75C32.75 26.8717 31.9071 28.9066 30.4069 30.4069C28.9066 31.9071 26.8717 32.75 24.75 32.75C22.6283 32.75 20.5934 31.9071 19.0931 30.4069C17.5929 28.9066 16.75 26.8717 16.75 24.75V8.75C16.75 6.62827 17.5929 4.59344 19.0931 3.09315ZM24.75 4.75C23.6891 4.75 22.6717 5.17143 21.9216 5.92157C21.1714 6.67172 20.75 7.68913 20.75 8.75V24.75C20.75 25.8109 21.1714 26.8283 21.9216 27.5784C22.6717 28.3286 23.6891 28.75 24.75 28.75C25.8109 28.75 26.8283 28.3286 27.5784 27.5784C28.3286 26.8283 28.75 25.8109 28.75 24.75V8.75C28.75 7.68913 28.3286 6.67172 27.5784 5.92157C26.8283 5.17143 25.8109 4.75 24.75 4.75ZM10.75 18.75C11.8546 18.75 12.75 19.6454 12.75 20.75V24.75C12.75 27.9326 14.0143 30.9848 16.2647 33.2353C18.5152 35.4857 21.5674 36.75 24.75 36.75C27.9326 36.75 30.9848 35.4857 33.2353 33.2353C35.4857 30.9848 36.75 27.9326 36.75 24.75V20.75C36.75 19.6454 37.6454 18.75 38.75 18.75C39.8546 18.75 40.75 19.6454 40.75 20.75V24.75C40.75 28.9935 39.0643 33.0631 36.0637 36.0637C33.5385 38.589 30.256 40.1829 26.75 40.6246V44.75H32.75C33.8546 44.75 34.75 45.6454 34.75 46.75C34.75 47.8546 33.8546 48.75 32.75 48.75H16.75C15.6454 48.75 14.75 47.8546 14.75 46.75C14.75 45.6454 15.6454 44.75 16.75 44.75H22.75V40.6246C19.244 40.1829 15.9615 38.589 13.4363 36.0637C10.4357 33.0631 8.75 28.9935 8.75 24.75V20.75C8.75 19.6454 9.64543 18.75 10.75 18.75Z"
          fill="white"
          fill-opacity="0.2"
        />
      </g>
      <defs>
        <clipPath id="clip0_4871_1798">
          <rect
            width="48"
            height="48"
            fill="white"
            transform="translate(0.75 0.75)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
