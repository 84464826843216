export default function MailIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.49995 4.16667C3.04352 4.16667 2.66662 4.54357 2.66662 5V15C2.66662 15.4564 3.04352 15.8333 3.49995 15.8333H16.8333C17.2897 15.8333 17.6666 15.4564 17.6666 15V5C17.6666 4.54357 17.2897 4.16667 16.8333 4.16667H3.49995ZM0.99995 5C0.99995 3.6231 2.12305 2.5 3.49995 2.5H16.8333C18.2102 2.5 19.3333 3.6231 19.3333 5V15C19.3333 16.3769 18.2102 17.5 16.8333 17.5H3.49995C2.12305 17.5 0.99995 16.3769 0.99995 15V5Z"
        fill="#151419"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.15059 4.52211C1.41452 4.14507 1.93413 4.05338 2.31117 4.31731L10.1666 9.81612L18.0221 4.31731C18.3991 4.05338 18.9187 4.14507 19.1826 4.52211C19.4466 4.89916 19.3549 5.41876 18.9778 5.68269L10.6445 11.516C10.3576 11.7169 9.97567 11.7169 9.68873 11.516L1.3554 5.68269C0.978356 5.41876 0.886661 4.89916 1.15059 4.52211Z"
        fill="#151419"
      />
    </svg>
  );
}
