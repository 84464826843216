export default function FileIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.23223 1.56573C3.70107 1.09689 4.33696 0.833496 5 0.833496H11.6667C11.8877 0.833496 12.0996 0.921293 12.2559 1.07757L17.2559 6.07757C17.4122 6.23385 17.5 6.44582 17.5 6.66683V16.6668C17.5 17.3299 17.2366 17.9658 16.7678 18.4346C16.2989 18.9034 15.663 19.1668 15 19.1668H5C4.33696 19.1668 3.70107 18.9034 3.23223 18.4346C2.76339 17.9658 2.5 17.3299 2.5 16.6668V3.3335C2.5 2.67045 2.76339 2.03457 3.23223 1.56573ZM5 2.50016C4.77899 2.50016 4.56702 2.58796 4.41074 2.74424C4.25446 2.90052 4.16667 3.11248 4.16667 3.3335V16.6668C4.16667 16.8878 4.25446 17.0998 4.41074 17.2561C4.56702 17.4124 4.77899 17.5002 5 17.5002H15C15.221 17.5002 15.433 17.4124 15.5893 17.2561C15.7455 17.0998 15.8333 16.8878 15.8333 16.6668V7.50016H11.6667C11.2064 7.50016 10.8333 7.12707 10.8333 6.66683V2.50016H5ZM12.5 3.67867L14.6548 5.8335H12.5V3.67867ZM5.83333 7.50016C5.83333 7.03993 6.20643 6.66683 6.66667 6.66683H8.33333C8.79357 6.66683 9.16667 7.03993 9.16667 7.50016C9.16667 7.9604 8.79357 8.3335 8.33333 8.3335H6.66667C6.20643 8.3335 5.83333 7.9604 5.83333 7.50016ZM5.83333 10.8335C5.83333 10.3733 6.20643 10.0002 6.66667 10.0002H13.3333C13.7936 10.0002 14.1667 10.3733 14.1667 10.8335C14.1667 11.2937 13.7936 11.6668 13.3333 11.6668H6.66667C6.20643 11.6668 5.83333 11.2937 5.83333 10.8335ZM5.83333 14.1668C5.83333 13.7066 6.20643 13.3335 6.66667 13.3335H13.3333C13.7936 13.3335 14.1667 13.7066 14.1667 14.1668C14.1667 14.6271 13.7936 15.0002 13.3333 15.0002H6.66667C6.20643 15.0002 5.83333 14.6271 5.83333 14.1668Z"
        fill="#9A97A8"
      />
    </svg>
  );
}
