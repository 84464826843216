export default function ChatIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g filter="url(#filter0_d_4476_3855)">
        <path
          d="M18.3331 9.38215C18.3364 10.6449 18.0414 11.8906 17.4721 13.0177C16.7971 14.3683 15.7593 15.5044 14.4751 16.2986C13.1909 17.0927 11.711 17.5137 10.201 17.5143C8.93827 17.5176 7.69261 17.2225 6.56548 16.6532L2.92742 17.8659C2.1795 18.1152 1.46795 17.4037 1.71725 16.6558L2.92994 13.0177C2.36063 11.8906 2.0656 10.6449 2.06889 9.38215C2.06948 7.87222 2.49043 6.39226 3.28461 5.10805C4.0788 3.82384 5.21483 2.78611 6.56548 2.11108C7.69261 1.54176 8.93827 1.24673 10.201 1.25003H10.6794C12.6735 1.36004 14.557 2.20174 15.9692 3.61395C17.3814 5.02616 18.2231 6.90965 18.3331 8.90379V9.38215Z"
          fill="#FDFCFF"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_4476_3855"
          x="-1"
          y="-0.5"
          width="22"
          height="22"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.5" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4476_3855"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4476_3855"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
