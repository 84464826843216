import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import styles from "./eviseCommission.module.scss";
import { EditPencil } from "../../atoms/icons";
import DialogModal from "../../atoms/dialog";
import { useEffect, useState } from "react";
import {
  fetchGlobalConfig,
  updateGlobalConfig,
} from "../../../services/configurations";
import CustomAlert from "../../atoms/alert/CustomAlert";
import Button from "../../atoms/button";

const EviseCommission = () => {
  const [commission, setCommission] = useState<any>();
  const [commissionModal, setCommissionModal] = useState(false);
  const [editCommission, setEditCommission] = useState<any>();
  const [alert, SetAlert] = useState<any>({
    show: false,
    state: "success",
    message: "",
  });
  const [callingApi, setCallingApi] = useState(false);

  const fetchEviseCommission = async () => {
    const res = await fetchGlobalConfig();
    if (res?.data?.status === "success") {
      setCommission(res?.data?.data);
    } else {
      console.log("Something went wrong");
    }
  };

  useEffect(() => {
    fetchEviseCommission();
  }, [callingApi]);

  const handleEditCommission = (commission: any) => {
    setCommissionModal(true);
    setEditCommission(commission);
  };

  const handleCommissionCloseModal = () => {
    setCommissionModal(false);
    SetAlert({
      show: false,
      state: "",
      message: "",
    });
  };

  const handleEditPayoutCommission = (e: any) => {
    const enteredValue = parseInt(e?.target?.value, 10);
    //const positiveValue = Math.abs(enteredValue);

    // Ensuring the entered value is between 0 and 100
    const positiveValue = Math.max(0, Math.min(100, enteredValue));

    setEditCommission({
      ...editCommission,
      commission: positiveValue,
    });
  };

  const handleUpdateCommission = async () => {
    const body = {
      commission: parseInt(editCommission?.commission),
    };
    const res = await updateGlobalConfig(editCommission?.id, body);
    if (res?.data?.status === "success") {
      setTimeout(() => {
        setCallingApi(!callingApi);
        handleCommissionCloseModal();
      }, 2000);
      SetAlert({
        show: true,
        state: "success",
        message: "Commission updated Successfully.",
      });
    } else {
      SetAlert({
        show: true,
        state: "error",
        message: res?.data?.exception?.error?.message,
      });
    }
  };

  const editCommissionModalBody = (
    <div>
      <div className="input-row input-with-icon" style={{ marginBottom: 0 }}>
        <div className="input-icon">
          <label style={{ marginBottom: 0 }}>Commission</label>
          <input
            type="number"
            className="input-field"
            style={{ paddingLeft: "1rem" }}
            value={editCommission?.commission}
            onChange={handleEditPayoutCommission}
          />
          <span>%</span>
        </div>
      </div>
      <Button
        variant="light"
        className="w-100 mt-3"
        onClick={handleUpdateCommission}
      >
        Save
      </Button>
      <CustomAlert
        show={alert.show}
        type={alert.state}
        message={alert.message}
        onClose={() => {
          SetAlert({ ...alert, show: false });
        }}
      />
    </div>
  );

  return (
    <div className={styles.plansWrapper}>
      <div>
        <p>Evise commission</p>
      </div>
      <section className="tableWrapper">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple-table">
            <TableHead>
              <TableRow>
                <TableCell align="left" sx={{ width: "90%" }}>
                  Commission (%)
                </TableCell>
                <TableCell align="left" sx={{ width: "10%" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {commission && (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {commission?.commission}
                  </TableCell>
                  <TableCell>
                    <span
                      onClick={() => handleEditCommission(commission)}
                      style={{ cursor: "pointer" }}
                    >
                      <EditPencil />
                    </span>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </section>
      {commissionModal && (
        <DialogModal
          openModal={commissionModal}
          setOpenModal={handleCommissionCloseModal}
          heading="Edit Commission"
          body={editCommissionModalBody}
        />
      )}
    </div>
  );
};

export default EviseCommission;
