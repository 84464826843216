export default function CompletedIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.3786 8.43136C18.758 8.03001 18.7402 7.3971 18.3389 7.0177C17.9376 6.63831 17.3046 6.6561 16.9252 7.05744L10.0894 14.2887L7.37865 11.4211C6.99925 11.0197 6.36633 11.0019 5.96499 11.3813C5.56365 11.7607 5.54585 12.3936 5.92525 12.795L9.36275 16.4314C9.55164 16.6312 9.81448 16.7444 10.0894 16.7444C10.3644 16.7444 10.6273 16.6312 10.8161 16.4314L18.3786 8.43136Z"
        fill="#2EA082"
      />
    </svg>
  );
}
