export default function ArchieveIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 5.16683C2.86819 5.16683 3.16667 5.46531 3.16667 5.8335V13.8335H13.8333V5.8335C13.8333 5.46531 14.1318 5.16683 14.5 5.16683C14.8682 5.16683 15.1667 5.46531 15.1667 5.8335V14.5002C15.1667 14.8684 14.8682 15.1668 14.5 15.1668H2.5C2.13181 15.1668 1.83333 14.8684 1.83333 14.5002V5.8335C1.83333 5.46531 2.13181 5.16683 2.5 5.16683Z"
        fill="#E0DFE3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 2.50016C0.5 2.13197 0.798477 1.8335 1.16667 1.8335H15.8333C16.2015 1.8335 16.5 2.13197 16.5 2.50016V5.8335C16.5 6.20169 16.2015 6.50016 15.8333 6.50016H1.16667C0.798477 6.50016 0.5 6.20169 0.5 5.8335V2.50016ZM1.83333 3.16683V5.16683H15.1667V3.16683H1.83333Z"
        fill="#E0DFE3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 8.50016C6.5 8.13197 6.79848 7.8335 7.16667 7.8335H9.83333C10.2015 7.8335 10.5 8.13197 10.5 8.50016C10.5 8.86835 10.2015 9.16683 9.83333 9.16683H7.16667C6.79848 9.16683 6.5 8.86835 6.5 8.50016Z"
        fill="#E0DFE3"
      />
    </svg>
  );
}
