import styles from "./customStatus.module.scss";

interface statusProps {
  status: "approved" | "pending" | "error" | "in-progress";
  message: string;
}

const CustomStatus = ({ status, message }: statusProps) => {
  return <div className={`${styles.status} ${styles[status]}`}>{message}</div>;
};

export default CustomStatus;
