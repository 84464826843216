export default function X() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <g clipPath="url(#clip0_577_14829)">
        <path
          d="M16.0844 0.833496H19.1512L12.4512 8.49122L20.3333 18.9116H14.1616L9.32783 12.5917L3.79684 18.9116H0.72819L7.89456 10.7209L0.333252 0.833496H6.66153L11.0309 6.61016L16.0844 0.833496ZM15.0081 17.076H16.7074L5.73816 2.57271H3.91458L15.0081 17.076Z"
          fill="#F7F9F9"
        />
      </g>
      <defs>
        <clipPath id="clip0_577_14829">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.333252)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
