export default function VideoIcon(props: any = {}) {
  const { color = "#9A97A8", ...resProps } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" color={color} {...resProps}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 5.00016C2.03976 5.00016 1.66667 5.37326 1.66667 5.8335V14.1668C1.66667 14.6271 2.03976 15.0002 2.5 15.0002H11.6667C12.1269 15.0002 12.5 14.6271 12.5 14.1668V5.8335C12.5 5.37326 12.1269 5.00016 11.6667 5.00016H2.5ZM14.1667 8.38084V5.8335C14.1667 4.45278 13.0474 3.3335 11.6667 3.3335H2.5C1.11929 3.3335 0 4.45278 0 5.8335V14.1668C0 15.5475 1.11929 16.6668 2.5 16.6668H11.6667C13.0474 16.6668 14.1667 15.5475 14.1667 14.1668V11.6195L18.6823 14.8449C18.9363 15.0264 19.2704 15.0506 19.548 14.9078C19.8255 14.765 20 14.479 20 14.1668V5.8335C20 5.52134 19.8255 5.23536 19.548 5.09252C19.2704 4.94969 18.9363 4.97395 18.6823 5.15538L14.1667 8.38084ZM14.7671 10.0002L18.3333 12.5475V7.45282L14.7671 10.0002Z"
        fill="currentColor"
      />
    </svg>
  );
}
