export default function VideoCallIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1667 8.38084V5.8335C14.1667 4.45278 13.0474 3.3335 11.6667 3.3335H2.5C1.11929 3.3335 0 4.45278 0 5.8335V14.1668C0 15.5475 1.11929 16.6668 2.5 16.6668H11.6667C13.0474 16.6668 14.1667 15.5475 14.1667 14.1668V11.6195L18.6823 14.8449C18.9363 15.0264 19.2704 15.0506 19.548 14.9078C19.8255 14.765 20 14.479 20 14.1668V5.8335C20 5.52134 19.8255 5.23536 19.548 5.09252C19.2704 4.94969 18.9363 4.97395 18.6823 5.15538L14.1667 8.38084Z"
        fill="#FDFCFF"
      />
    </svg>
  );
}
