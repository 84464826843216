import { HTTP_METHODS } from "../common/constants";
import { makeNetworkCall } from "./network";
const BaseURL = process.env.REACT_APP_API_BASE_URL;
const BaseAdminUrl = `${process.env.REACT_APP_API_BASE_URL}admin`;


//const BaseAuthURL = "https://evise-devapi.kellton.net/auth";

export const getIssues = () => {
  let url = `${BaseAdminUrl}/issue`;
  return makeNetworkCall(HTTP_METHODS.GET, url);
};

export const getMessages = (id:string) => {
  let url = `${BaseAdminUrl}/issue/${id}`;
  return makeNetworkCall(HTTP_METHODS.GET, url);
};

export const postMessages = (id:string, body:any) => {
  let url = `${BaseAdminUrl}/issue/${id}`;
  return makeNetworkCall(HTTP_METHODS.POST, url, body);
};



