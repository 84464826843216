import React from "react";

export default function TrashIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.33366 2.50016C8.11265 2.50016 7.90068 2.58796 7.7444 2.74424C7.58812 2.90052 7.50033 3.11248 7.50033 3.3335V4.16683H12.5003V3.3335C12.5003 3.11248 12.4125 2.90052 12.2562 2.74424C12.1 2.58796 11.888 2.50016 11.667 2.50016H8.33366ZM14.167 4.16683V3.3335C14.167 2.67045 13.9036 2.03457 13.4348 1.56573C12.9659 1.09689 12.33 0.833496 11.667 0.833496H8.33366C7.67062 0.833496 7.03473 1.09689 6.56589 1.56573C6.09705 2.03457 5.83366 2.67045 5.83366 3.3335V4.16683H2.50033C2.04009 4.16683 1.66699 4.53993 1.66699 5.00016C1.66699 5.4604 2.04009 5.8335 2.50033 5.8335H3.33366V16.6668C3.33366 17.3299 3.59705 17.9658 4.06589 18.4346C4.53473 18.9034 5.17062 19.1668 5.83366 19.1668H14.167C14.83 19.1668 15.4659 18.9034 15.9348 18.4346C16.4036 17.9658 16.667 17.3299 16.667 16.6668V5.8335H17.5003C17.9606 5.8335 18.3337 5.4604 18.3337 5.00016C18.3337 4.53993 17.9606 4.16683 17.5003 4.16683H14.167ZM5.00033 5.8335V16.6668C5.00033 16.8878 5.08812 17.0998 5.2444 17.2561C5.40068 17.4124 5.61265 17.5002 5.83366 17.5002H14.167C14.388 17.5002 14.6 17.4124 14.7562 17.2561C14.9125 17.0998 15.0003 16.8878 15.0003 16.6668V5.8335H5.00033ZM8.33366 8.3335C8.7939 8.3335 9.16699 8.70659 9.16699 9.16683V14.1668C9.16699 14.6271 8.7939 15.0002 8.33366 15.0002C7.87342 15.0002 7.50033 14.6271 7.50033 14.1668V9.16683C7.50033 8.70659 7.87342 8.3335 8.33366 8.3335ZM10.8337 9.16683C10.8337 8.70659 11.2068 8.3335 11.667 8.3335C12.1272 8.3335 12.5003 8.70659 12.5003 9.16683V14.1668C12.5003 14.6271 12.1272 15.0002 11.667 15.0002C11.2068 15.0002 10.8337 14.6271 10.8337 14.1668V9.16683Z"
        fill="#D86561"
      />
    </svg>
  );
}
