export default function RightIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.74408 4.41058C8.06951 4.08514 8.59715 4.08514 8.92259 4.41058L13.9226 9.41058C14.248 9.73602 14.248 10.2637 13.9226 10.5891L8.92259 15.5891C8.59715 15.9145 8.06951 15.9145 7.74408 15.5891C7.41864 15.2637 7.41864 14.736 7.74408 14.4106L12.1548 9.99984L7.74408 5.58909C7.41864 5.26366 7.41864 4.73602 7.74408 4.41058Z"
        fill="#E0DFE3"
      />
    </svg>
  );
}
