export default function LeftArrowIcon({ className }: { className?: any }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      className={className}
    >
       {" "}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.3335 10.6C3.3335 10.1398 3.70659 9.76668 4.16683 9.76668H15.8335C16.2937 9.76668 16.6668 10.1398 16.6668 10.6C16.6668 11.0603 16.2937 11.4333 15.8335 11.4333H4.16683C3.70659 11.4333 3.3335 11.0603 3.3335 10.6Z"
        fill="#E0DFE3"
      />
       {" "}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.41091 4.17743C9.73634 3.85199 10.264 3.85199 10.5894 4.17743L16.4228 10.0108C16.7482 10.3362 16.7482 10.8638 16.4228 11.1893L10.5894 17.0226C10.264 17.348 9.73634 17.348 9.41091 17.0226C9.08547 16.6972 9.08547 16.1695 9.41091 15.8441L14.655 10.6L9.41091 5.35594C9.08547 5.0305 9.08547 4.50286 9.41091 4.17743Z"
        fill="#E0DFE3"
      />
    </svg>
  );
}
