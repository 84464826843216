export default function ImageIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.66699 5.33317C5.93061 5.33317 5.33366 5.93012 5.33366 6.6665V25.3332C5.33366 25.9049 5.69353 26.3926 6.19911 26.5821L20.3909 12.3904C20.9115 11.8697 21.7558 11.8697 22.2765 12.3904L26.667 16.7809V6.6665C26.667 5.93012 26.07 5.33317 25.3337 5.33317H6.66699ZM29.3337 19.9985V6.6665C29.3337 4.45737 27.5428 2.6665 25.3337 2.6665H6.66699C4.45785 2.6665 2.66699 4.45736 2.66699 6.6665V25.3332C2.66699 27.5423 4.45785 29.3332 6.66699 29.3332H25.3337C27.5428 29.3332 29.3337 27.5423 29.3337 25.3332V20.0011C29.3337 20.0003 29.3337 19.9994 29.3337 19.9985ZM26.667 20.5521L21.3337 15.2188L9.88594 26.6665H25.3337C26.07 26.6665 26.667 26.0696 26.667 25.3332V20.5521ZM11.3337 10.6665C10.9655 10.6665 10.667 10.965 10.667 11.3332C10.667 11.7014 10.9655 11.9998 11.3337 11.9998C11.7018 11.9998 12.0003 11.7014 12.0003 11.3332C12.0003 10.965 11.7018 10.6665 11.3337 10.6665ZM8.00033 11.3332C8.00033 9.49222 9.49271 7.99984 11.3337 7.99984C13.1746 7.99984 14.667 9.49222 14.667 11.3332C14.667 13.1741 13.1746 14.6665 11.3337 14.6665C9.49271 14.6665 8.00033 13.1741 8.00033 11.3332Z"
          fill="#FDFCFF"
        />
      </g>
    </svg>
  );
}
