export default function DollarIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0003 0C10.4606 0 10.8337 0.373096 10.8337 0.833333V3.33333H14.167C14.6272 3.33333 15.0003 3.70643 15.0003 4.16667C15.0003 4.6269 14.6272 5 14.167 5H10.8337V9.16667H12.0837C13.0782 9.16667 14.032 9.56175 14.7353 10.265C15.4386 10.9683 15.8337 11.9221 15.8337 12.9167C15.8337 13.9112 15.4386 14.8651 14.7353 15.5683C14.032 16.2716 13.0782 16.6667 12.0837 16.6667H10.8337V19.1667C10.8337 19.6269 10.4606 20 10.0003 20C9.54009 20 9.16699 19.6269 9.16699 19.1667V16.6667H5.00033C4.54009 16.6667 4.16699 16.2936 4.16699 15.8333C4.16699 15.3731 4.54009 15 5.00033 15H9.16699V10.8333H7.91699C6.92243 10.8333 5.9686 10.4382 5.26534 9.73498C4.56208 9.03172 4.16699 8.0779 4.16699 7.08333C4.16699 6.08877 4.56208 5.13494 5.26534 4.43168C5.9686 3.72842 6.92243 3.33333 7.91699 3.33333H9.16699V0.833333C9.16699 0.373096 9.54009 0 10.0003 0ZM9.16699 5H7.91699C7.36446 5 6.83455 5.21949 6.44385 5.61019C6.05315 6.0009 5.83366 6.5308 5.83366 7.08333C5.83366 7.63587 6.05315 8.16577 6.44385 8.55647C6.83455 8.94717 7.36446 9.16667 7.91699 9.16667H9.16699V5ZM10.8337 10.8333V15H12.0837C12.6362 15 13.1661 14.7805 13.5568 14.3898C13.9475 13.9991 14.167 13.4692 14.167 12.9167C14.167 12.3641 13.9475 11.8342 13.5568 11.4435C13.1661 11.0528 12.6362 10.8333 12.0837 10.8333H10.8337Z"
        fill="#9A97A8"
      />
    </svg>
  );
}
