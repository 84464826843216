export default function SendMessageIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
    >
      <g filter="url(#filter0_d_3940_2841)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.2504 1.23786C1.50439 0.989223 1.88813 0.929044 2.20604 1.088L20.5394 10.2547C20.8217 10.3958 21 10.6844 21 11C21 11.3157 20.8217 11.6042 20.5394 11.7454L2.20604 20.912C1.88411 21.073 1.49521 21.0091 1.24173 20.7536C0.988252 20.498 0.927477 20.1086 1.09103 19.788L4.46823 13.1676C4.58784 12.9332 4.8112 12.769 5.07069 12.7248L10.8862 11.9173C11.7606 11.7959 11.7433 10.5264 10.8658 10.429L5.09809 9.78869C4.81901 9.75068 4.57801 9.57427 4.45744 9.31972L1.08024 2.19009C0.928089 1.86888 0.996417 1.48651 1.2504 1.23786Z"
          fill="#151419"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3940_2841"
          x="0"
          y="0.5"
          width="22"
          height="22"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.5" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3940_2841"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3940_2841"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
