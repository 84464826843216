export default function Linkedin() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <g clipPath="url(#clip0_577_14826)">
        <path
          d="M17.7043 16.8715H14.7409V12.2764C14.7409 11.1807 14.7212 9.77012 13.1996 9.77012C11.6562 9.77012 11.4201 10.964 11.4201 12.1966V16.8712H8.45669V7.42186H11.3015V8.7132H11.3414C11.6261 8.23122 12.0375 7.83471 12.5317 7.56591C13.026 7.29712 13.5847 7.1661 14.1482 7.18682C17.1517 7.18682 17.7055 9.14294 17.7055 11.6877L17.7043 16.8715ZM5.11302 6.1302C4.7729 6.13026 4.44039 6.03046 4.15755 5.84341C3.87472 5.65636 3.65427 5.39046 3.52406 5.07935C3.39384 4.76824 3.35971 4.42588 3.42601 4.09556C3.4923 3.76525 3.65604 3.46182 3.8965 3.22365C4.13696 2.98547 4.44335 2.82325 4.77692 2.75748C5.11049 2.69172 5.45626 2.72538 5.77052 2.8542C6.08477 2.98303 6.35339 3.20122 6.5424 3.4812C6.73141 3.76119 6.83234 4.09038 6.8324 4.42715C6.83244 4.65076 6.78799 4.87218 6.7016 5.07878C6.61522 5.28539 6.4886 5.47312 6.32894 5.63126C6.16928 5.78941 5.97971 5.91486 5.77108 6.00047C5.56246 6.08608 5.33885 6.13016 5.11302 6.1302ZM6.5947 16.8715H3.62824V7.42186H6.5947V16.8715ZM19.1816 -0.000115677H2.13916C1.75234 -0.00443791 1.37959 0.143475 1.10286 0.411119C0.826132 0.678764 0.668051 1.04424 0.66333 1.42724V18.3719C0.667889 18.7551 0.825878 19.1208 1.1026 19.3887C1.37932 19.6567 1.75215 19.8049 2.13916 19.8008H19.1816C19.5694 19.8056 19.9433 19.6578 20.2211 19.3899C20.4989 19.122 20.658 18.7558 20.6633 18.3719V1.42602C20.6578 1.04226 20.4986 0.676366 20.2208 0.408728C19.943 0.14109 19.5692 -0.00640011 19.1816 -0.00133875"
          fill="#0A66C2"
        />
        <path
          d="M5.11302 6.1302C4.7729 6.13026 4.44039 6.03046 4.15755 5.84341C3.87472 5.65636 3.65427 5.39046 3.52406 5.07935C3.39384 4.76824 3.35971 4.42588 3.42601 4.09556C3.4923 3.76525 3.65604 3.46182 3.8965 3.22365C4.13696 2.98547 4.44335 2.82325 4.77692 2.75748C5.11049 2.69172 5.45626 2.72538 5.77052 2.8542C6.08477 2.98303 6.35339 3.20122 6.5424 3.4812C6.73141 3.76119 6.83234 4.09038 6.8324 4.42715C6.83244 4.65076 6.78799 4.87218 6.7016 5.07878C6.61522 5.28539 6.4886 5.47312 6.32894 5.63126C6.16928 5.78941 5.97971 5.91486 5.77108 6.00047C5.56246 6.08608 5.33885 6.13016 5.11302 6.1302Z"
          fill="white"
        />
        <path
          d="M6.5947 16.8715H3.62824V7.42186H6.5947V16.8715Z"
          fill="white"
        />
        <path
          d="M17.7043 16.8715H14.7409V12.2764C14.7409 11.1807 14.7212 9.77012 13.1996 9.77012C11.6562 9.77012 11.4201 10.964 11.4201 12.1966V16.8712H8.45669V7.42186H11.3015V8.7132H11.3414C11.6261 8.23122 12.0375 7.83471 12.5317 7.56591C13.026 7.29712 13.5847 7.1661 14.1482 7.18682C17.1517 7.18682 17.7055 9.14294 17.7055 11.6877L17.7043 16.8715Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_577_14826">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.666504)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
