import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../atoms/button";
import { CaretIcon } from "../../atoms/icons";
import styles from "./customerInteractions.module.scss";

type props = {
  totalCount: number;
};

const CustomerInteractionsHeader: React.FC<props> = ({ totalCount }) => {
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const [limitCount, setLimitCount] = useState<any>(10);
  const [totalPage, setTotalPage] = useState<any>("1");
  const [medium, setMedium] = useState<string>("ALL");

  const handleLimitChange = (event: any) => {
    setLimitCount(event.target.value);
    setPageNumber(1)
  };
  const handleSourceChange = (event: SelectChangeEvent) => {
    setMedium(event.target.value as string);
    setPageNumber(1);
  };

  useEffect(() => {
    let queryParams = `limit=${limitCount}`;
    if (pageNumber !== 1) {
      queryParams += `&skip=${limitCount * (pageNumber - 1)}`;
    }
    if (medium !== "ALL") {
      queryParams += `&medium=${medium}`;
    }

    navigate({
      pathname: "",
      search: `?${queryParams}`,
    });
    setTotalPage(Math.ceil(totalCount / limitCount));
  }, [limitCount, pageNumber, navigate, totalCount, medium]);

  return (
    <div className={styles.interactionsHeader}>
      <div>
        <Button variant="greyNoBorder">
          <Select
            labelId="earningSource"
            id="earningSource"
            value={medium}
            label="earningSource"
            onChange={handleSourceChange}
            IconComponent={CaretIcon}
            renderValue={(value: string) => {
              return (
                <>
                  {value !== "ALL" ? (
                    <span>Medium : {value === "DIGIDOUBLE" ? "Digi-Double" : "Zoom"}</span>
                  ) : (
                    <span>Medium</span>
                  )}
                </>
              );
            }}
          >
            <MenuItem value={"ALL"}>All</MenuItem>
            <MenuItem value={"DIGIDOUBLE"}>Digi-Double</MenuItem>
            <MenuItem value={"VIDEOCALL"}>Zoom</MenuItem>
          </Select>
        </Button>
      </div>

      <div>
        <div className={styles.pagination}>
          <Button variant="greyNoBorder">
            Results:
            <Select
              labelId="limitCount"
              id="limitCount"
              value={limitCount}
              label="limitCount"
              IconComponent={CaretIcon}
              onChange={handleLimitChange}
            >
              <MenuItem value={10}>10 / Page</MenuItem>
              <MenuItem value={20}>20 / Page</MenuItem>
              <MenuItem value={30}>30 / Page</MenuItem>
            </Select>{" "}
          </Button>

          <Button variant="greyNoBorder" className={styles.paginationNav}>
            <small
              className={`${styles.prevPage} ${pageNumber === 1 && styles.disabled}`}
              onClick={() => {
                pageNumber > 1 && setPageNumber(pageNumber - 1);
              }}
            >
              <CaretIcon />
            </small>
            <span>{pageNumber}</span> / {totalPage || 1}
            <small
              className={`${styles.nextPage} ${pageNumber === totalPage && styles.disabled}`}
              onClick={() => {
                pageNumber < totalPage && setPageNumber(pageNumber + 1);
              }}
            >
              <CaretIcon />
            </small>
          </Button>
        </div>
      </div>
    </div>
  );
};
export default CustomerInteractionsHeader;
