import { useEffect, useState } from "react";
import TabPanel from "../../components/atoms/tabPanel";
import EnterpriseInfoCard from "../../components/molecules/customerInfoCard/enterpriseInfoCard";
import EnterpriseLicenses from "../../components/organisms/enterpriseLicenses";
import EnterprisePrograms from "../../components/organisms/enterprisePrograms";
import Header from "../../components/organisms/header";
import EnterpriseProfileProvider from "./enterpriseProfile.context";

type props = {};

const EnterpriseProfile: React.FC<props> = () => {
  const [badgeList, setBadgeList] = useState([]);
  const [callingLicenseApi, setCallingLicenseApi] = useState(false);

  useEffect(() => {
    // fetchBadge()?.then((res: any) => {
    //   const response = res?.data;
    //   if (response.status === "success") {
    //     setBadgeList(response.data.badges);
    //   }
    // });
  }, []);

  return (
    <div className="mainWrapper">
      <Header />
      <EnterpriseProfileProvider>
        <div className="contentWrapper">
          <EnterpriseInfoCard
            badgeList={badgeList}
            api={callingLicenseApi}
            setApi={setCallingLicenseApi}
          />
          <TabPanel
            panels={[
              {
                tabLabel: "Licenses",
                tabContent: (
                  <EnterpriseLicenses
                    api={callingLicenseApi}
                    setApi={setCallingLicenseApi}
                  />
                ),
              },
              {
                tabLabel: "Programs",
                tabContent: <EnterprisePrograms />,
              },
              // {
              //   tabLabel: "Interactions",
              //   // tabContent: <CustomerCalendar />,
              //   tabContent: <div>Calendar: Lorem Ipsum</div>,
              // },
              // {
              //   tabLabel: "Calendar",
              //   tabContent: <CustomerCalendar />,
              // },
              // {
              //   tabLabel: "Transactions",
              //   // tabContent: <CustomerEarnings />,
              //   tabContent: <div>Earnings: Lorem Ipsum</div>,
              // },
            ]}
          />

          {/* <EnterpriseInfoCard badgeList={badgeList} />
          <TabPanel
            panels={[
              {
                tabLabel: "Interactions",
                tabContent: <div>Interactions: Lorem Ipsum</div>,
              },
              {
                tabLabel: "Calendar",
                tabContent: <CustomerCalendar />,
              },
              {
                tabLabel: "Transactions",
                // tabContent: <CustomerEarnings />,
                tabContent: <div>Earnings: Lorem Ipsum</div>,
              },
              {
                tabLabel: "Activity log",
                // tabContent: <ActivityLog />,
                tabContent: <div>Activity log: Lorem Ipsum</div>,
              },
            ]}
          /> */}
        </div>
      </EnterpriseProfileProvider>
    </div>
  );
};
export default EnterpriseProfile;
