import { HTTP_METHODS } from "../common/constants";
import { makeNetworkCall } from "./network";
const BaseAuthURL = `${process.env.REACT_APP_API_BASE_URL}admin`;
const baseUrl = `${process.env.REACT_APP_API_BASE_URL}auth`;

export const getIndustries = () => {
  const url = `${baseUrl}/industry`;
  return makeNetworkCall(HTTP_METHODS.GET, url);
};

export const getLOBs = () => {
  const url = `${baseUrl}/line-of-business`;
  return makeNetworkCall(HTTP_METHODS.GET, url);
};

export const handleLogin = (body: any) => {
  const url = `${BaseAuthURL}/magic-link`;
  return makeNetworkCall(HTTP_METHODS.POST, url, body);
};

export const retrieveToken = (tmpToken: string) => {
  const url = `${BaseAuthURL}/user/token`;
  return makeNetworkCall(HTTP_METHODS.POST, url, { token: tmpToken });
};
