export default function MicIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49"
      height="49"
      viewBox="0 0 49 49"
      fill="none"
    >
      <g clipPath="url(#clip0_4880_1693)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.5931 3.09315C20.0934 1.59285 22.1283 0.75 24.25 0.75C26.3717 0.75 28.4066 1.59285 29.9069 3.09315C31.4071 4.59344 32.25 6.62827 32.25 8.75V24.75C32.25 26.8717 31.4071 28.9066 29.9069 30.4069C28.4066 31.9071 26.3717 32.75 24.25 32.75C22.1283 32.75 20.0934 31.9071 18.5931 30.4069C17.0929 28.9066 16.25 26.8717 16.25 24.75V8.75C16.25 6.62827 17.0929 4.59344 18.5931 3.09315ZM24.25 4.75C23.1891 4.75 22.1717 5.17143 21.4216 5.92157C20.6714 6.67172 20.25 7.68913 20.25 8.75V24.75C20.25 25.8109 20.6714 26.8283 21.4216 27.5784C22.1717 28.3286 23.1891 28.75 24.25 28.75C25.3109 28.75 26.3283 28.3286 27.0784 27.5784C27.8286 26.8283 28.25 25.8109 28.25 24.75V8.75C28.25 7.68913 27.8286 6.67172 27.0784 5.92157C26.3283 5.17143 25.3109 4.75 24.25 4.75ZM10.25 18.75C11.3546 18.75 12.25 19.6454 12.25 20.75V24.75C12.25 27.9326 13.5143 30.9848 15.7647 33.2353C18.0152 35.4857 21.0674 36.75 24.25 36.75C27.4326 36.75 30.4848 35.4857 32.7353 33.2353C34.9857 30.9848 36.25 27.9326 36.25 24.75V20.75C36.25 19.6454 37.1454 18.75 38.25 18.75C39.3546 18.75 40.25 19.6454 40.25 20.75V24.75C40.25 28.9935 38.5643 33.0631 35.5637 36.0637C33.0385 38.589 29.756 40.1829 26.25 40.6246V44.75H32.25C33.3546 44.75 34.25 45.6454 34.25 46.75C34.25 47.8546 33.3546 48.75 32.25 48.75H16.25C15.1454 48.75 14.25 47.8546 14.25 46.75C14.25 45.6454 15.1454 44.75 16.25 44.75H22.25V40.6246C18.744 40.1829 15.4615 38.589 12.9363 36.0637C9.93571 33.0631 8.25 28.9935 8.25 24.75V20.75C8.25 19.6454 9.14543 18.75 10.25 18.75Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4880_1693">
          <rect
            width="48"
            height="48"
            fill="white"
            transform="translate(0.25 0.75)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
