export default function Youtube() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8139 3.87676C18.6744 4.10744 19.352 4.78715 19.582 5.65031C20 7.21473 20 10.4788 20 10.4788C20 10.4788 20 13.7428 19.582 15.3074C19.352 16.1704 18.6744 16.8501 17.8139 17.0809C16.2542 17.5001 10 17.5001 10 17.5001C10 17.5001 3.74583 17.5001 2.18612 17.0809C1.32564 16.8501 0.647905 16.1704 0.417924 15.3074C0 13.7428 0 10.4788 0 10.4788C0 10.4788 0 7.21473 0.417924 5.65031C0.647905 4.78715 1.32564 4.10744 2.18612 3.87676C3.74583 3.45752 10 3.45752 10 3.45752C10 3.45752 16.2542 3.45752 17.8139 3.87676ZM13.1818 10.479L7.95456 13.4424V7.51532L13.1818 10.479Z"
        fill="#ED1D24"
      />
      <path
        d="M7.95456 13.4424L13.1818 10.479L7.95456 7.51532V13.4424Z"
        fill="white"
      />
    </svg>
  );
}
