import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import { Box, Drawer, IconButton } from "@mui/material";
import { useState, useEffect } from "react";
import HeaderResponsive from "./headerResponsive";
import styles from "./header.module.scss";
import {
  DashboardIcon,
  UsersIcon,
  SupportIcon,
  ConfigurationIcon,
} from "../../atoms/icons";
import { NavLink } from "react-router-dom";
import { getIssues } from "../../../services/support";

function Header() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [newMessageCount, setNewMessageCount] = useState(0);

  const logOutHandler = () => {
    localStorage.clear();
  };

  useEffect(() => {
    getIssues()?.then((res: any) => {
      setNewMessageCount(res?.data?.data?.unreadCount);
    });
  }, []);

  return (
    <header className={styles.header}>
      <div className={styles.headerMobile}>
        <IconButton
          onClick={() => setIsDrawerOpen(true)}
          size="large"
          edge="start"
          color="inherit"
          aria-label="logo"
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          anchor="left"
          open={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
        >
          <Box
            sx={{ background: "#151419" }}
            p={2}
            width="300px"
            role="presentation"
            textAlign="center"
          >
            <HeaderResponsive />
          </Box>
        </Drawer>
      </div>
      <div className={styles.headerLeft}>
        <img src="/assets/images/header-logo.svg" loading="lazy" alt="" />
        <ul className={styles.menuItems}>
          <li>
            <NavLink to="/dashboard">
              <DashboardIcon />
              Dashboard
            </NavLink>
          </li>
          <li>
            <NavLink to="/experts">
              <UsersIcon /> Experts
            </NavLink>
          </li>
          <li>
            <NavLink to="/customers">
              <UsersIcon /> Customers
            </NavLink>
          </li>
          <li>
            <NavLink to="/configurations">
              <ConfigurationIcon /> Configurations
            </NavLink>
          </li>
          <li>
            <NavLink to="/support">
              <SupportIcon /> Support
              {!!newMessageCount && (
                <sup className={styles.notification}>{newMessageCount}</sup>
              )}
            </NavLink>
          </li>
        </ul>
      </div>

      <ul className={styles.headerRight}>
        <li className="dropdown">
          <div
            id="logoutDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              className={styles.userIcon}
              src={"/assets/images/evise-admin.jpg"}
              alt="profile pic"
            />
            <KeyboardArrowDownOutlinedIcon />
          </div>

          <ul className="dropdown-menu" aria-labelledby="logoutDropdown">
            <li>
              <a
                className="dropdown-item"
                onClick={logOutHandler}
                href="/login"
              >
                Log out
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </header>
  );
}

export default Header;
