export default function Medium() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <g clipPath="url(#clip0_577_14836)">
        <path
          d="M11.6145 9.84607C11.6145 12.9827 9.08904 15.5254 5.97395 15.5254C2.85886 15.5254 0.333252 12.9827 0.333252 9.84607C0.333252 6.70943 2.85867 4.1665 5.97395 4.1665C9.08923 4.1665 11.6145 6.70943 11.6145 9.84607Z"
          fill="white"
        />
        <path
          d="M17.8023 9.84608C17.8023 12.7985 16.5396 15.193 14.9819 15.193C13.4243 15.193 12.1616 12.7985 12.1616 9.84608C12.1616 6.89361 13.4241 4.4992 14.9817 4.4992C16.5394 4.4992 17.8023 6.89284 17.8023 9.84608Z"
          fill="white"
        />
        <path
          d="M20.3333 9.84607C20.3333 12.4908 19.8892 14.636 19.3413 14.636C18.7934 14.636 18.3496 12.4913 18.3496 9.84607C18.3496 7.2008 18.7936 5.05611 19.3413 5.05611C19.889 5.05611 20.3333 7.20061 20.3333 9.84607Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_577_14836">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.333252)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
