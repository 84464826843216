import {
  MenuItem,
  Paper,
  Popover,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import styles from "./customerTransactions.module.scss";
import Button from "../../atoms/button";
import {
  CaretIcon,
  ChatIcon,
  InboxIcon,
  LeftArrowIcon,
  VideoIcon,
} from "../../atoms/icons";
import { useEffect, useState } from "react";
import "react-day-picker/dist/style.css";
import { DateRange, DayPicker } from "react-day-picker";
import CustomToggle from "../../atoms/customToggle";
import { fetchCustomerTransactions } from "../../../services/customerListing";
import { useParams } from "react-router-dom";

const toggleValues = [
  {
    id: "All",
    name: "All",
  },
  {
    id: "DEBIT",
    name: "Debit",
  },
  {
    id: "CREDIT",
    name: "Credit",
  },
];

const CustomerTransactions = () => {
  const [type, setType] = useState<string>("All");
  const [source, setSource] = useState<string>("All");
  const [range, setRange] = useState<DateRange | undefined>(undefined);
  const [resultsCount, setResultsCount] = useState<any>(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPage, setTotalPage] = useState<any>("");
  const { customerId } = useParams();
  const [transactionsList, setTransactionsList] = useState<any>([]);
  const [transactionsCount, setTransactionsCount] = useState<any>();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const getCustomerTransactionsList = async () => {
    const filterParams = `customerId=${customerId}&limit=${resultsCount}${
      pageNumber !== 1 ? `&skip=${resultsCount * (pageNumber - 1)}` : ""
    }${type !== "All" ? `&type=${type}` : ""}${
      source !== "All" ? `&source=${source}` : ""
    }${
      range?.to !== undefined
        ? `&startTime=${range?.from?.toISOString()}&endTime=${new Date(
            range?.to?.setHours(23, 59, 59, 999)
          ).toISOString()}`
        : ""
    }`;
    const res = await fetchCustomerTransactions(filterParams);
    if (res?.data?.status === "success") {
      console.log(res?.data?.data?.licenseCustomerData);
      setTransactionsList(res?.data?.data?.customerTransactions);
      setTransactionsCount(res?.data?.data?.transactionCount);
    } else {
      console.log("Something went wrong");
    }
  };

  useEffect(() => {
    customerId && getCustomerTransactionsList();

    if (transactionsCount === 0) setTotalPage(1);
    else setTotalPage(Math.ceil(transactionsCount / resultsCount));
  }, [resultsCount, type, source, transactionsCount, range?.to, pageNumber]);

  useEffect(() => {
    setPageNumber(1);
  }, [transactionsCount, totalPage]);

  const handleSourceChange = (event: SelectChangeEvent) => {
    setSource(event.target.value as string);
  };
  const handleResultsChange = (event: SelectChangeEvent) => {
    setResultsCount(parseInt(event.target.value));
  };

  return (
    <section className={styles.transactionsWrapper}>
      {/* Header */}
      <section className={styles.transactionsHeader}>
        <div className={styles.transactionFilter}>
          <div className={styles.filters}>
            <CustomToggle type={type} setType={setType} values={toggleValues} />

            <Button variant="greyNoBorder">
              Type:
              <Select
                labelId="earningSource"
                id="earningSource"
                value={source}
                label="earningSource"
                onChange={handleSourceChange}
                IconComponent={CaretIcon}
              >
                <MenuItem value={"All"}>All</MenuItem>
                <MenuItem value={"VIDEOCALL"}>Video Call</MenuItem>
                <MenuItem value={"AIVA"}>A.I.va</MenuItem>
              </Select>
            </Button>

            <div>
              <Button
                type="button"
                aria-describedby={"datepickerPopper"}
                variant="greyNoBorder"
                onClick={handleClick}
              >
                Date range:
                {range?.from &&
                  range?.from.toLocaleString("en-US", {
                    hour12: false,
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  }) +
                    " -" +
                    ((range?.to &&
                      range?.to.toLocaleString("en-US", {
                        hour12: false,
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      })) ||
                      "Select Range")}
                <CaretIcon />
              </Button>
              <Popover
                id="datepickerPopper"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <DayPicker
                  id="react-day-picker"
                  mode="range"
                  initialFocus={false}
                  selected={range}
                  onSelect={setRange}
                  numberOfMonths={2}
                  toDate={new Date()}
                  defaultMonth={
                    new Date(new Date().setMonth(new Date().getMonth() - 1))
                  }
                />
              </Popover>
            </div>
          </div>

          <div className={styles.pagination}>
            <Button variant="greyNoBorder">
              Results:
              <Select
                labelId="resultsCount"
                id="resultsCount"
                value={resultsCount}
                label="resultsCount"
                onChange={handleResultsChange}
                IconComponent={CaretIcon}
              >
                <MenuItem value={10}>10 / Page</MenuItem>
                <MenuItem value={20}>20 / Page</MenuItem>
                <MenuItem value={30}>30 / Page</MenuItem>
              </Select>{" "}
            </Button>

            <Button variant="greyNoBorder" className={styles.paginationNav}>
              <small
                className={`${styles.prevPage} ${
                  pageNumber === 1 && styles.disabled
                }`}
                onClick={() => {
                  pageNumber > 1 && setPageNumber(pageNumber - 1);
                }}
              >
                <CaretIcon />
              </small>
              <span>{pageNumber}</span> / {totalPage}
              <small
                className={`${styles.nextPage}  ${
                  pageNumber === totalPage && styles.disabled
                }`}
                onClick={() => {
                  pageNumber < totalPage && setPageNumber(pageNumber + 1);
                }}
              >
                <CaretIcon />
              </small>
            </Button>
          </div>
        </div>
      </section>

      {/* Table */}
      <section className="tableWrapper">
        {transactionsList?.length > 0 ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Industry</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Date & time</TableCell>
                  <TableCell>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactionsList?.map((row: any) => (
                  <TableRow
                    key={row?.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row?.source === "PAYOUT"
                        ? "Bank transfer"
                        : row?.userData?.name}
                    </TableCell>
                    <TableCell>
                      {
                        <>
                          <span className="iconCircle">
                            <img
                              src={
                                row?.userData?.industry?.iconUrl
                                  ? row?.userData?.industry?.iconUrl
                                  : "/assets/images/industry.png"
                              }
                              alt=""
                            />
                          </span>
                          {row?.source === "PAYOUT"
                            ? "NA"
                            : row?.userData?.industry?.name}
                        </>
                      }
                    </TableCell>
                    <TableCell>
                      {(row?.source === "VIDEOCALL" && (
                        <>
                          <span className="iconCircle">
                            <VideoIcon />
                          </span>
                          Video call
                        </>
                      )) ||
                        (row?.source === "AIVA" && (
                          <>
                            <span className="iconCircle">
                              <ChatIcon />
                            </span>
                            A.I.va
                          </>
                        )) ||
                        (row?.source === "PAYOUT" && (
                          <>
                            <span className="iconCircle">
                              <LeftArrowIcon className="payoutIcon" />
                            </span>
                            Payout
                          </>
                        )) ||
                        row?.source}
                    </TableCell>
                    <TableCell>
                      {new Date(row?.createdAt).toLocaleString("en-IN", {
                        hour12: false,
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                      })}
                    </TableCell>
                    <TableCell
                      className={
                        row?.type === "CREDIT" ? "txt-success" : "txt-danger"
                      }
                    >
                      {row?.type === "CREDIT" ? "+" : "-"}
                      {row?.amount} USD
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div className="noData">
            <InboxIcon />
            <p>No transactions yet</p>
          </div>
        )}
      </section>
    </section>
  );
};

export default CustomerTransactions;
