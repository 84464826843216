export default function CaretIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.41107 6.91058C4.73651 6.58514 5.26414 6.58514 5.58958 6.91058L10.0003 11.3213L14.4111 6.91058C14.7365 6.58514 15.2641 6.58514 15.5896 6.91058C15.915 7.23602 15.915 7.76366 15.5896 8.08909L10.5896 13.0891C10.2641 13.4145 9.73651 13.4145 9.41107 13.0891L4.41107 8.08909C4.08563 7.76366 4.08563 7.23602 4.41107 6.91058Z"
        fill="#E0DFE3"
      />
    </svg>
  );
}
