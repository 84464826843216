import { AlertColor } from "@mui/material";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useCustomerProfile } from "../../../pages/customerProfile/customerProfile.context";
import { useEnterpriseProfile } from "../../../pages/enterpriseProfile/enterpriseProfile.context";
import {
  smeProfileAddRecommendation,
  smeProfileApprove,
  smeProfileReject
} from "../../../services/smeListing";
import CustomAlert from "../../atoms/alert/CustomAlert";
import Button from "../../atoms/button";
import styles from "./customerInfoCard.module.scss";

type patchApiProps = {
  id: string;
  onSuccess?: (a: any) => void;
  onError?: (a: any) => void;
  setOpenModal?: () => void;
};

type alertProps = {
  show: boolean;
  type: AlertColor;
  message: string;
};

const alertDefaultState: alertProps = {
  show: false,
  type: "success",
  message: "",
};

export function ApproveAccount({ id, onSuccess, onError, setOpenModal }: patchApiProps) {
  // const { refreshProfile } = useExpertProfile();
  const [butttonState, setButtonState] = useState<"success" | "disabled">("success");
  const [alert, SetAlert] = useState<alertProps>(alertDefaultState);

  function handleResponse(res: any) {
    if (res?.data?.status === "success") {
      // refreshProfile();
      setButtonState("disabled");
      SetAlert({
        show: true,
        type: "success",
        message: "User Approved successfully.",
      });
      onSuccess && onSuccess(res);
    } else {
      SetAlert({
        show: true,
        type: "error",
        message: res?.data?.exception?.error?.message,
      });
      onError && onError(res);
    }
  }
  return (
    <div className={styles.suspendBlock}>
      <p>Are you sure you'd like to approve this expert?</p>
      <Button
        type="button"
        variant={butttonState}
        className="w-100"
        onClick={() => {
          smeProfileApprove(id)?.then(handleResponse);
        }}
      >
        Approve
      </Button>
      <CustomAlert
        show={alert.show}
        type={alert.type}
        message={alert.message}
        onClose={() => {
          SetAlert({ ...alert, show: false });
          !!setOpenModal && setOpenModal();
        }}
      />
    </div>
  );
}

export function RejectAccount({ id, onSuccess, onError, setOpenModal }: patchApiProps) {
  // const { refreshProfile } = useExpertProfile();
  const [butttonState, setButtonState] = useState<"error" | "disabled">("error");
  const [alert, SetAlert] = useState<alertProps>({
    show: false,
    type: "success",
    message: "",
  });

  function handleResponse(res: any) {
    if (res?.data?.status === "success") {
      // refreshProfile();
      setButtonState("disabled");
      SetAlert({
        show: true,
        type: "success",
        message: "User Rejected successfully.",
      });
      onSuccess && onSuccess(res);
    } else {
      SetAlert({
        show: true,
        type: "error",
        message: res?.data?.exception?.error?.message,
      });
      onError && onError(res);
    }
  }
  return (
    <div className={styles.suspendBlock}>
      <p>Are you sure you'd like to reject this expert?</p>
      <Button
        type="button"
        variant={butttonState}
        className="w-100"
        onClick={() => {
          smeProfileReject(id)?.then(handleResponse);
        }}
      >
        Reject
      </Button>
      <CustomAlert
        show={alert.show}
        type={alert.type}
        message={alert.message}
        onClose={() => {
          SetAlert({ ...alert, show: false });
          !!setOpenModal && setOpenModal();
        }}
      />
    </div>
  );
}

export function SuspendAccount({ id, onSuccess, onError, setOpenModal }: patchApiProps) {
  const { suspendCustomer } = useCustomerProfile();
  const [butttonState, setButtonState] = useState<"light" | "disabled">("light");
  const [alert, SetAlert] = useState<alertProps>({
    show: false,
    type: "success",
    message: "",
  });

  function handleResponse(res: any) {
    setButtonState("disabled");
    if (res?.data?.status === "success") {
      SetAlert({
        show: true,
        type: "success",
        message: "Account is suspended now.",
      });
    } else {
      SetAlert({
        show: true,
        type: "error",
        message: "Somthing went Wrong",
      });
      setButtonState("light");
    }
  }

  return (
    <div className={styles.suspendBlock}>
      <p>Are you sure you'd like to suspend this user?</p>
      <Button
        type="button"
        variant={butttonState}
        className={`w-100`}
        onClick={() => {
          suspendCustomer(handleResponse);
        }}
      >
        Suspend
      </Button>
      <CustomAlert
        show={alert.show}
        type={alert.type}
        message={alert.message}
        onClose={() => {
          SetAlert({ ...alert, show: false });
          !!setOpenModal && setOpenModal();
        }}
      />
    </div>
  );
}

export const RevokedAccount = ({ id, onSuccess, onError, setOpenModal }: patchApiProps) => {
  const [butttonState, setButtonState] = useState<"light" | "disabled">("light");
  const [alert, SetAlert] = useState<alertProps>({
    show: false,
    type: "success",
    message: "",
  });
  const { revokedCustomer } = useCustomerProfile();

  function handleResponse(res: any) {
    setButtonState("disabled");
    if (res?.data?.status === "success") {
      SetAlert({
        show: true,
        type: "success",
        message: "Account suspension is revoked now.",
      });
    } else {
      SetAlert({
        show: true,
        type: "error",
        message: "Somthing went Wrong",
      });
      setButtonState("light");
    }
  }

  return (
    <div className={styles.suspendBlock}>
      <p>Are you sure you'd like to revoke this user suspension?</p>
      <Button
        type="button"
        variant={butttonState}
        className={`w-100`}
        onClick={() => {
          revokedCustomer(handleResponse);
        }}
      >
        Revoke Suspension
      </Button>
      <CustomAlert
        show={alert.show}
        type={alert.type}
        message={alert.message}
        onClose={() => {
          SetAlert({ ...alert, show: false });
          !!setOpenModal && setOpenModal();
        }}
      />
    </div>
  );
};

type addRecommendationProps = {
  recommendations?: any;
  smeProfileId: string;
  onSuccess?: (a: any) => void;
  onError?: (a: any) => void;
};

export function AddRecommendation({ recommendations, smeProfileId }: addRecommendationProps) {
  // const { refreshProfile } = useExpertProfile();
  const [textValue, setTextValue] = useState("");

  //const recommendationsList = recommendations[0]?.description?.split("\n");

  const butttonState = useMemo(() => {
    return !!textValue ? "light" : "disabled";
  }, [textValue]);
  const [alert, SetAlert] = useState<alertProps>({
    show: false,
    type: "success",
    message: "",
  });

  function handleResponse(res: any) {
    if (res?.data?.status === "success") {
      //setTextValue("");
      // refreshProfile();
      SetAlert({
        show: true,
        type: "success",
        message: "Recommendation added successfully.",
      });
      // onSuccess && onSuccess(res);
    } else {
      SetAlert({
        show: true,
        type: "error",
        message: res?.data?.exception?.error?.message,
      });
      // onError && onError(res);
    }
  }
  return (
    <div className={styles.addRecommendationBlock}>
      {/* {recommendations.length > 0 && (
        <div className={styles.recommendations}>
          {recommendations.map((recommendation: any) => {
            return (
              <div className={styles.recommendationTextBox}>
                {" "}
                <Circle className={styles.bullets} />
                <p> {recommendation?.description}</p>
              </div>
            );
          })}
        </div>
      )} */}
      <div className="input-row ">
        <textarea
          className="textarea-field"
          placeholder="Write the recommendation here..."
          rows={6}
          defaultValue={recommendations[0].description || ""}
          onChange={(e: any) => {
            setTextValue(e.target.value);
          }}
        ></textarea>
        <small>Note: Please write each recommendation in a new line</small>
      </div>

      <Button
        type="button"
        variant={butttonState}
        className="w-100"
        onClick={() => {
          smeProfileAddRecommendation({
            smeProfileId: smeProfileId,
            description: textValue,
          })?.then(handleResponse);
        }}
      >
        Add
      </Button>

      <CustomAlert
        show={alert.show}
        type={alert.type}
        message={alert.message}
        onClose={() => {
          SetAlert({ ...alert, show: false });
        }}
      />
    </div>
  );
}

type inviteTeamMemberProps = {
  api: any;
  setApi: any;
  onClose?: (info?: any) => void;
};

export function InviteTeamMember({ api, setApi, onClose }: inviteTeamMemberProps) {
  const { inviteNewMember } = useEnterpriseProfile();
  const [newMemberEmail, setNewMemberEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [alert, SetAlert] = useState<alertProps>(alertDefaultState);
  const [isLoading, setIsloading] = useState(false);

  const handleInvites = () => {
    setIsloading(true);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(newMemberEmail)) {
      setEmailError("");
      inviteNewMember(newMemberEmail, (res) => {
        if (res?.status === "success") {
          setApi(!api);
          SetAlert({
            show: true,
            type: "success",
            message: "Invite mail sent.",
          });
        } else {
          setIsloading(false);
          if (res?.statusCode === 409) {
            onClose && onClose(newMemberEmail);
          } else {
            SetAlert({
              show: true,
              type: "error",
              message: res?.exception?.error?.message || "Somthing went wrong!",
            });
          }
        }
      });
    } else {
      setEmailError("Please enter a valid Email.");
    }
  };

  return (
    <>
      <div className="input-row">
        <label> Email address</label>
        <input
          className={`input-field`}
          value={newMemberEmail}
          onChange={(e) => {
            setNewMemberEmail(e.target.value);
          }}
          type="email"
          placeholder="example@mail.com"
        />
        {emailError && <p className="text-danger">{emailError}</p>}
      </div>
      <Button className="w-100" variant={isLoading ? "disabled" : "light"} onClick={handleInvites}>
        Invite
      </Button>
      <CustomAlert
        show={alert.show}
        type={alert.type}
        message={alert.message}
        onClose={() => {
          SetAlert({ ...alert, show: false });
          alert.type === "success" && onClose && onClose();
        }}
      />
    </>
  );
}

type existingUserUpgradeProps = {
  newEmail: string;
  onClose?: (info?: any) => void;
};
export function ExistingUserUpgrade({ newEmail, onClose }: existingUserUpgradeProps) {
  const { upgradeToEnterpisePlan } = useEnterpriseProfile();
  const [alert, SetAlert] = useState<alertProps>(alertDefaultState);
  const [isLoading, setIsloading] = useState(false);

  const handleInvites = () => {
    setIsloading(true);
    upgradeToEnterpisePlan(newEmail, (res)=>{
      if (res.status === "success") {
        SetAlert({
          show: true,
          type: "success",
          message: "Invite mail sent.",
        });
      } else {
        setIsloading(false);
        SetAlert({
          show: true,
          type: "error",
          message: res?.exception?.error?.message || "Somthing went wrong!",
        });
      }
    });
  };

  return (
    <>
      <div className="input-row">
        <p className=" mb-3">{newEmail}</p>
        <small className="text-warning">
          User already exists. Do you want to upgrade the user to Enterprise user.
        </small>
      </div>
      <Button className="w-100" variant={isLoading ? "disabled" : "light"} onClick={handleInvites}>
        Upgrade & Invite
      </Button>
      <CustomAlert
        show={alert.show}
        type={alert.type}
        message={alert.message}
        onClose={() => {
          SetAlert({ ...alert, show: false });
          alert.type === "success" && onClose && onClose();
        }}
      />
    </>
  );
}

type editPlan = {
  onClose?: () => void;
};
export function EditPlan({ onClose }: editPlan) {
  const { enterpriseInfo, updateEnterprise } = useEnterpriseProfile();
  const [isLoading, setLoading] = useState(false);
  const [alert, SetAlert] = useState<alertProps>(alertDefaultState);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = handleSubmit((data) => {
    setLoading(true);
    const payload = {
      ...data ,
       isAdd:false,
    } 

    updateEnterprise(payload, (res) => {
      if (res.status === "success") {
        SetAlert({
          show: true,
          type: "success",
          message: "Plan update successfully.",
        });
      } else {
        setLoading(false);
        SetAlert({
          show: true,
          type: "error",
          message: res?.exception?.error?.message || "Somthing went wrong!",
        });
      }
    });
  });

  return (
    <form onSubmit={onSubmit}>
      <div className="input-row">
        <label>Number of seats </label>
        <input
          className={`input-field`}
          type="text"
          placeholder="0"
          defaultValue={enterpriseInfo?.seats}
          {...register("seats", {
            required: "This field is required.",
            pattern: {
              value: /^\d+$/,
              message: "Please enter a positive number",
            },
            validate: (value) => parseInt(value) >= 5 || "Minimum seats can be 5.",
          })}
        />
        <span style={{ marginLeft: "-4rem", color: "#F3F2F7" }}>seats</span>
        {errors?.seats?.message && <p className="text-danger">{`${errors.seats.message}`}</p>}
      </div>

      <div className="input-row">
        <label> Annual call hours </label>
        <input
          className={`input-field`}
          type="text"
          placeholder="0"
          defaultValue={enterpriseInfo?.callHours}
          {...register("callHours", {
            required: "This field is required.",
            pattern: {
              value: /^\d+$/,
              message: "Please enter a positive number",
            },
            validate: (value) => parseInt(value) >= 3 || "Minimum hours can be 3.",
          })}
        />
        <span style={{ marginLeft: "-4rem", color: "#F3F2F7" }}>hours</span>
        {errors?.callHours?.message && <p className="text-danger">{`${errors.callHours.message}`}</p>}
      </div>

      <Button type="submit" className="w-100" variant={isLoading ? "disabled" : "light"}>
        Save
      </Button>

      <CustomAlert
        show={alert.show}
        type={alert.type}
        message={alert.message}
        onClose={() => {
          SetAlert({ ...alert, show: false });
          alert.type === "success" && onClose && onClose();
        }}
      />
    </form>
  );
}

type addHours = {
  onClose?: () => void;
};
export function AddHours({ onClose }: addHours) {
  const { enterpriseInfo, updateEnterprise } = useEnterpriseProfile();
  const [isLoading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [alert, SetAlert] = useState<alertProps>(alertDefaultState);

  const onSubmit = () => {
    setLoading(true);
    const payload = {
      callHours: Number(inputValue) || 0,
      isAdd:true,
    };
    updateEnterprise(payload, (res) => {
      if (res?.status === "success") {
        SetAlert({
          show: true,
          type: "success",
          message: "Hours updated.",
        });
      } else {
        setLoading(false);
        SetAlert({
          show: true,
          type: "error",
          message: res?.exception?.error?.message || "Somthing went wrong!",
        });
      }
    });
  };
  return (
    <div>
      <div className="input-row">
        <input
          className={`input-field`}
          type="text"
          placeholder="5"
          value={inputValue}
          onChange={(e) => {
            const val = Number(e.target.value) || "";
            if (val === "" || val >= 0 || val <= 9) {
              setInputValue(`${val}`);
            }
          }}
          disabled={isLoading}
        />
        <span style={{ marginLeft: "-4rem", color: "#F3F2F7" }}>Hours</span>
      </div>

      <Button
        type="button"
        className="w-100"
        variant={isLoading || !inputValue ? "disabled" : "light"}
        onClick={onSubmit}
      >
        Add
      </Button>

      <CustomAlert
        show={alert.show}
        type={alert.type}
        message={alert.message}
        onClose={() => {
          SetAlert({ ...alert, show: false });
          alert.type === "success" && onClose && onClose();
        }}
      />
    </div>
  );
}

export function EditAnnualRevenue({ onClose }: addHours) {
  const { enterpriseInfo, updateEnterprise } = useEnterpriseProfile();
  const [isLoading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState(enterpriseInfo.totalRevenue);
  const [alert, SetAlert] = useState<alertProps>(alertDefaultState);

  const onSubmit = () => {
    setLoading(true);
    const payload = {
      totalRevenue: Number(inputValue) || 0,
      isAdd:false,
    };
    updateEnterprise(payload, (res) => {
      if (res?.status === "success") {
        SetAlert({
          show: true,
          type: "success",
          message: "Total annual revenue updated.",
        });
      } else {
        setLoading(false);
        SetAlert({
          show: true,
          type: "error",
          message: res?.exception?.error?.message || "Somthing went wrong!",
        });
      }
    });
  };
  return (
    <div>
      <div className="input-row">
        <input
          className={`input-field`}
          type="text"
          placeholder="5"
          value={inputValue}
          onChange={(e) => {
            const val = Number(e.target.value) || "";
            if (val === "" || val >= 0 || val <= 9) {
              setInputValue(`${val}`);
            }
          }}
          disabled={isLoading}
        />
        <span style={{ marginLeft: "-4rem", color: "#F3F2F7" }}>USD</span>
      </div>

      <Button
        type="button"
        className="w-100"
        variant={isLoading || !inputValue ? "disabled" : "light"}
        onClick={onSubmit}
      >
        Add
      </Button>

      <CustomAlert
        show={alert.show}
        type={alert.type}
        message={alert.message}
        onClose={() => {
          SetAlert({ ...alert, show: false });
          alert.type === "success" && onClose && onClose();
        }}
      />
    </div>
  );
}
