export default function LeftIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2556 4.41058C12.581 4.73602 12.581 5.26366 12.2556 5.58909L7.84485 9.99984L12.2556 14.4106C12.581 14.736 12.581 15.2637 12.2556 15.5891C11.9302 15.9145 11.4025 15.9145 11.0771 15.5891L6.07709 10.5891C5.75165 10.2637 5.75165 9.73602 6.07709 9.41058L11.0771 4.41058C11.4025 4.08514 11.9302 4.08514 12.2556 4.41058Z"
        fill="#E0DFE3"
      />
    </svg>
  );
}
