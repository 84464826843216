import { ToggleButton, ToggleButtonGroup } from "@mui/material";

interface toggleProps {
  type: any;
  setType: any;
  values: {
    id: any;
    name: string;
  }[];
}

const CustomToggle = ({ type, setType, values }: toggleProps) => {
  const handleTypeChange = (event: any) => {
    setType(event.target.value);
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={type}
      exclusive
      aria-label="Platform"
      className="customTabs"
      onChange={handleTypeChange}
    >
      {values?.length > 0 &&
        values?.map((value) => (
          <ToggleButton key={value?.id} value={value?.id}>
            {value?.name}
          </ToggleButton>
        ))}
    </ToggleButtonGroup>
  );
};

export default CustomToggle;
