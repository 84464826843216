import {
  IconButton,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { formatInDateTime } from "../../../helper/time";
import { fetchCustomerInteraction } from "../../../services/customerListing";
import { fetchRecording } from "../../../services/meetings";
import { VideoIcon } from "../../atoms/icons";
import styles from "./customerInteractions.module.scss";
import CustomerInteractionsHeader from "./customerInteractionsHeader";

type props = {};
type interactionDataProps = {
  interactions: any[];
  totalInteractions: number;
};
const interactionDataSample: interactionDataProps = {
  interactions: [],
  totalInteractions: 0,
};
const CustomerInteractions: React.FC<props> = () => {
  const { customerId = "" } = useParams();
  const [interactionData, setInteractionData] = useState<interactionDataProps>(interactionDataSample);
  const [apiLoading, setApiLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const limit = searchParams.get("limit") || 10;
  const skip = searchParams.get("skip") || 0;
  const medium = searchParams.get("medium");

  const fetchData = useCallback(() => {
    setApiLoading(true);
    fetchCustomerInteraction({
      customerId: customerId,
      limit: limit,
      skip: skip,
      ...(medium ? { medium: medium } : {}),
    })?.then((res: any) => {
      const resData = res?.data || {};
      if (resData.status === "success") {
        setInteractionData(resData.data);
      }
      setApiLoading(false);
    });
  }, [customerId, limit, medium, skip]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <div className={styles.transactionsWrapper}>
        <CustomerInteractionsHeader totalCount={interactionData.totalInteractions} />

        <section className="tableWrapper">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>SME</TableCell>
                  <TableCell>Medium</TableCell>
                  <TableCell>Date & time</TableCell>
                  <TableCell width={120}>Recording</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!!interactionData.interactions.length &&
                  interactionData.interactions.map((interaction: any) => {
                    return (
                      <TableRow
                        key={interaction.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>
                          <p>{interaction.smeName}</p>
                        </TableCell>
                        <TableCell>
                          <p>{interaction.medium === "VIDEOCALL" ? "Zoom" : "Digi-Double"}</p>
                        </TableCell>
                        <TableCell component="td" scope="row">
                          <p>
                            {formatInDateTime({
                              date: interaction.createdAt,
                              fullMonth: false,
                              timeHours: 24,
                            })}
                          </p>
                        </TableCell>
                        <TableCell className="text-center">
                          {interaction.medium === "VIDEOCALL" && <ViewRecording id={interaction.id} />}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            {!interactionData.interactions.length && (
              <big className={`noDataBigText ${styles.noData} my-5`}>No data available</big>
            )}
          </TableContainer>
        </section>
      </div>
    </>
  );
};
export default CustomerInteractions;

const ViewRecording = ({ id }: { id: string }) => {
  const [apiLoading, setApiLoading] = useState<"idle" | "pending" | "success" | "failed">("idle");
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const ref = useRef<HTMLButtonElement | null>(null);
  const [videoDetails, setVideoDetails] = useState<any>();
  const [statusMsg, setStatusMsg] = useState("");

  const getVideoDetails = () => {
    if (!videoDetails) {
      setApiLoading("pending");
      fetchRecording(id).then((res: any) => {
        const statusCode = res?.status;
        const resData = res.data || {};
        if (resData?.status === "success") {
          const videoData = resData?.data;
          setVideoDetails(videoData);
          setApiLoading("success");
        } else {
          const msg = resData?.exception?.error?.message || "Something went wrong!";
          setStatusMsg(msg);
          setApiLoading("failed");
        }
      });
    }
  };

  return (
    <>
      <Popover
        id={id}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <div className={styles.popoverBox}>
          {apiLoading === "failed" ? (
            <div className={styles.popoverError}>{statusMsg}</div>
          ) : (
            <div className={styles.popoverInfo}>
              {apiLoading === "pending" && <big className="text-white">Loading...</big>}
              {apiLoading === "success" && (
                <div>
                  <p>
                    <strong>Meeting link:</strong>{" "}
                    <a href={videoDetails.fileData.play_url} target="_blank" rel="noreferrer">
                      Go to zoom
                    </a>
                  </p>
                  <p>
                    <strong>Passcode :</strong> {videoDetails.paasword || "NA"}{" "}
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </Popover>

      {/* <Button
        title="View recording"
        variant="blue"
        disabled={apiLoading === "pending"}
        className={styles.btnRecording}
        onClick={(event: any) => {
          setAnchorEl(event.currentTarget);
          getVideoDetails();
        }}
      >
        <VideoCameraFrontSharp />
      </Button> */}

      <IconButton
        title="View recording"
        aria-label="View recording"
        className={styles.btnRecording}
        // color={apiLoading !== "failed" ? "info" : "error"}
        onClick={(event: any) => {
          setAnchorEl(event.currentTarget);
          getVideoDetails();
        }}
      >
        <VideoIcon />
      </IconButton>
    </>
  );
};
