export default function InboxIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8133 6L33.8533 6C34.9695 6.00059 36.0645 6.31257 37.0132 6.90084C37.961 7.4886 38.7262 8.32888 39.223 9.32736C39.2234 9.32824 39.2239 9.32912 39.2243 9.33L46.1216 23.1045C46.2608 23.3825 46.3333 23.6891 46.3333 24V36C46.3333 37.5913 45.7011 39.1174 44.5759 40.2426C43.4507 41.3679 41.9246 42 40.3333 42H8.33325C6.74195 42 5.21583 41.3679 4.09061 40.2426C2.96539 39.1174 2.33325 37.5913 2.33325 36V24C2.33325 23.6891 2.40572 23.3825 2.54492 23.1045L9.44219 9.33C9.44267 9.32903 9.44316 9.32805 9.44365 9.32708C9.94042 8.32872 10.7056 7.48854 11.6533 6.90084C12.602 6.31257 13.697 6.00059 14.8133 6ZM14.8139 10C14.442 10.0003 14.0775 10.1043 13.7614 10.3003C13.4451 10.4964 13.1899 10.7768 13.0243 11.11L13.0216 11.1155L7.57142 22H16.3333C17.002 22 17.6264 22.3342 17.9974 22.8906L21.4036 28H27.2629L30.6692 22.8906C31.0401 22.3342 31.6645 22 32.3333 22H41.0951L35.6449 11.1155L35.6422 11.11C35.4766 10.7768 35.2214 10.4964 34.9051 10.3003C34.589 10.1043 34.2245 10.0003 33.8526 10H14.8139ZM42.3333 26H33.4036L29.9974 31.1094C29.6264 31.6658 29.002 32 28.3333 32H20.3333C19.6645 32 19.0401 31.6658 18.6692 31.1094L15.2629 26H6.33325V36C6.33325 36.5304 6.54397 37.0391 6.91904 37.4142C7.29411 37.7893 7.80282 38 8.33325 38H40.3333C40.8637 38 41.3724 37.7893 41.7475 37.4142C42.1225 37.0391 42.3333 36.5304 42.3333 36V26Z"
        fill="#5A576A"
      />
    </svg>
  );
}
