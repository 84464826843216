export default function AttachmentIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="attachment">
        <path
          id="icon"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 6.66667C4.11594 6.66667 3.2681 7.01786 2.64298 7.64298C2.01786 8.2681 1.66667 9.11594 1.66667 10C1.66667 10.8841 2.01786 11.7319 2.64298 12.357C2.95251 12.6666 3.31997 12.9121 3.72439 13.0796C4.12881 13.2471 4.56226 13.3333 5 13.3333H7.5C7.96024 13.3333 8.33333 13.7064 8.33333 14.1667C8.33333 14.6269 7.96024 15 7.5 15H5C4.34339 15 3.69321 14.8707 3.08658 14.6194C2.47995 14.3681 1.92876 13.9998 1.46447 13.5355C0.526784 12.5979 0 11.3261 0 10C0 8.67392 0.526784 7.40215 1.46447 6.46447C2.40215 5.52678 3.67392 5 5 5H7.5C7.96024 5 8.33333 5.3731 8.33333 5.83333C8.33333 6.29357 7.96024 6.66667 7.5 6.66667H5ZM11.6667 5.83333C11.6667 5.3731 12.0398 5 12.5 5H15C15.6566 5 16.3068 5.12933 16.9134 5.3806C17.52 5.63188 18.0712 6.00017 18.5355 6.46447C18.9998 6.92876 19.3681 7.47995 19.6194 8.08658C19.8707 8.69321 20 9.34339 20 10C20 10.6566 19.8707 11.3068 19.6194 11.9134C19.3681 12.52 18.9998 13.0712 18.5355 13.5355C18.0712 13.9998 17.52 14.3681 16.9134 14.6194C16.3068 14.8707 15.6566 15 15 15H12.5C12.0398 15 11.6667 14.6269 11.6667 14.1667C11.6667 13.7064 12.0398 13.3333 12.5 13.3333H15C15.4377 13.3333 15.8712 13.2471 16.2756 13.0796C16.68 12.9121 17.0475 12.6666 17.357 12.357C17.6666 12.0475 17.9121 11.68 18.0796 11.2756C18.2471 10.8712 18.3333 10.4377 18.3333 10C18.3333 9.56226 18.2471 9.12881 18.0796 8.72439C17.9121 8.31997 17.6666 7.95251 17.357 7.64298C17.0475 7.33345 16.68 7.08792 16.2756 6.9204C15.8712 6.75289 15.4377 6.66667 15 6.66667H12.5C12.0398 6.66667 11.6667 6.29357 11.6667 5.83333Z"
          fill="#ADAAB5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.83333 10C5.83333 9.53976 6.20643 9.16667 6.66667 9.16667H13.3333C13.7936 9.16667 14.1667 9.53976 14.1667 10C14.1667 10.4602 13.7936 10.8333 13.3333 10.8333H6.66667C6.20643 10.8333 5.83333 10.4602 5.83333 10Z"
          fill="#ADAAB5"
        />
      </g>
    </svg>
  );
}
