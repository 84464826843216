export default function SubStack() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_494_17042)">
        <path d="M18.749 4.50514H1.25V6.86885H18.749V4.50514Z" fill="#FF6719" />
        <path
          d="M1.25 9.01027V20L9.9991 15.092L18.7497 20V9.01027H1.25Z"
          fill="#FF6719"
        />
        <path d="M18.749 0H1.25V2.36332H18.749V0Z" fill="#FF6719" />
      </g>
      <defs>
        <clipPath id="clip0_494_17042">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
