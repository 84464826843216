export function BlackPlusIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0002 3.33334C10.4604 3.33334 10.8335 3.70644 10.8335 4.16668V15.8333C10.8335 16.2936 10.4604 16.6667 10.0002 16.6667C9.53993 16.6667 9.16683 16.2936 9.16683 15.8333V4.16668C9.16683 3.70644 9.53993 3.33334 10.0002 3.33334Z"
        fill="#151419"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.3335 10C3.3335 9.53977 3.70659 9.16668 4.16683 9.16668H15.8335C16.2937 9.16668 16.6668 9.53977 16.6668 10C16.6668 10.4602 16.2937 10.8333 15.8335 10.8333H4.16683C3.70659 10.8333 3.3335 10.4602 3.3335 10Z"
        fill="#151419"
      />
    </svg>
  );
}
