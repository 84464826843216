import styles from "./header.module.scss";
import {
  DashboardIcon,
  UsersIcon,
  SupportIcon,
  ConfigurationIcon,
} from "../../atoms/icons";
import { NavLink } from "react-router-dom";
import { getIssues } from "../../../services/support";
import { useState, useEffect } from "react";

function HeaderResponsive() {
  const [newMessageCount, setNewMessageCount] = useState(0);

  useEffect(() => {
    getIssues()?.then((res: any) => {
      setNewMessageCount(res?.data?.data?.unreadCount);
    });
  }, []);

  return (
    <div className={styles.dynamicHeader}>
      <div className={styles.logo}>
        <img src="./assets/images/header-logo.svg" loading="lazy" />
      </div>
      <ul className={styles.menuItems}>
        <li>
          <NavLink to="/dashboard">
            <DashboardIcon />
            Dashboard
          </NavLink>
        </li>
        <li>
          <NavLink to="/experts">
            <UsersIcon /> Experts
          </NavLink>
        </li>
        <li>
          <NavLink to="/customers">
            <UsersIcon /> Customers
          </NavLink>
        </li>
        <li>
          <NavLink to="/configurations">
            <ConfigurationIcon /> Configurations
          </NavLink>
        </li>
        <li>
          <NavLink to="/support">
            <SupportIcon /> Support
            {!!newMessageCount && (
              <sup className={styles.notification}>{newMessageCount}</sup>
            )}
          </NavLink>
        </li>
      </ul>
    </div>
  );
}

export default HeaderResponsive;
