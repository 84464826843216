export default function SearchIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.16663 3.33334C5.94496 3.33334 3.33329 5.94501 3.33329 9.16667C3.33329 12.3883 5.94496 15 9.16663 15C12.3883 15 15 12.3883 15 9.16667C15 5.94501 12.3883 3.33334 9.16663 3.33334ZM1.66663 9.16667C1.66663 5.02454 5.02449 1.66667 9.16663 1.66667C13.3088 1.66667 16.6666 5.02454 16.6666 9.16667C16.6666 13.3088 13.3088 16.6667 9.16663 16.6667C5.02449 16.6667 1.66663 13.3088 1.66663 9.16667Z"
        fill="#E0DFE3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2857 13.2857C13.6111 12.9603 14.1388 12.9603 14.4642 13.2857L18.0892 16.9107C18.4147 17.2362 18.4147 17.7638 18.0892 18.0893C17.7638 18.4147 17.2361 18.4147 16.9107 18.0893L13.2857 14.4643C12.9603 14.1388 12.9603 13.6112 13.2857 13.2857Z"
        fill="#E0DFE3"
      />
    </svg>
  );
}
