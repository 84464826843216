import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  getAivaPayout,
  updateAivaPayoutAmount,
} from "../../../services/configurations";
import CustomAlert from "../../atoms/alert/CustomAlert";
import Button from "../../atoms/button";
import DialogModal from "../../atoms/dialog";
import { EditPencil } from "../../atoms/icons";
import styles from "./aivaPayout.module.scss";

type alertProps = {
  show: boolean;
  state: "success" | "error";
  message: string;
};
const AivaPayout = () => {
  const [editPayoutModal, setEditPayoutModal] = useState(false);
  const [editPayoutAmount, setEditPayoutAmount] = useState("");
  const [aivaPayoutConfig, setAivaPayoutConfig] = useState<any>();
  const [alert, SetAlert] = useState<alertProps>({
    show: false,
    state: "success",
    message: "",
  });

  const handleEditPayout = (amount: string) => {
    setEditPayoutModal(true);
    setEditPayoutAmount(amount);
  };

  const handleEditPayoutAmount = (event: any) => {
    setEditPayoutAmount(
      event.target.value
        .replace(/[^0-9.]/g, "")
        .replace(/(\..*)\./g, "$1")
        .replace(/^(\d+\.\d{2}).*$/, "$1")
    );
  };

  function handleUpdateAmount() {
    const payload = {
      amount: Number(editPayoutAmount),
      payoutId: `${aivaPayoutConfig?.id}`,
    };
    updateAivaPayoutAmount(payload)?.then((res) => {
      const response = res?.data;
      if (response?.status === "success") {
        SetAlert({
          show: true,
          state: "success",
          message: "A.I.va payout amount updated.",
        });
        setAivaPayoutConfig(response.data);
      } else {
        SetAlert({
          show: true,
          state: "error",
          message: res?.data?.exception?.error?.message,
        });
      }
    });
  }

  useEffect(() => {
    getAivaPayout()?.then((res) => {
      const response = res?.data;
      if (response?.status === "success") {
        setAivaPayoutConfig(response.data);
      }
    });
  }, []);

  const editPayoutModalBody = (
    <div>
      <div className="input-row input-with-icon" style={{ marginBottom: 0 }}>
        <div className="input-icon">
          <label style={{ marginBottom: 0 }}>Amount</label>
          <input
            type="text"
            className="input-field"
            style={{ paddingLeft: "1rem" }}
            value={editPayoutAmount}
            onChange={handleEditPayoutAmount}
          />
          <span>USD</span>
        </div>
      </div>
      <Button
        variant="light"
        className="w-100 mt-3"
        onClick={handleUpdateAmount}
      >
        Save
      </Button>
      <CustomAlert
        show={alert.show}
        type={alert.state}
        message={alert.message}
        onClose={() => {
          SetAlert({ ...alert, show: false });
        }}
      />
      {/* <Collapse in={alert.show} unmountOnExit>
        <Alert
          className="mt-3 rounded-pill"
          variant="filled"
          severity={alert.state}
          icon={""}
          onClose={() => {
            SetAlert({ show: false, state: "success", message: "" });
          }}
        >
          {alert.message}
        </Alert>
      </Collapse> */}
    </div>
  );

  return (
    <div className={styles.plansWrapper}>
      <div>
        <p>A.I.va payout</p>
      </div>
      <section className="tableWrapper">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple-table">
            <TableHead>
              <TableRow>
                <TableCell align="left" sx={{ width: "45%" }}>
                  Amount
                </TableCell>
                <TableCell align="left" sx={{ width: "45%" }}>
                  Last payout
                </TableCell>
                <TableCell align="left" sx={{ width: "10%" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {aivaPayoutConfig && (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {aivaPayoutConfig.amount} USD
                  </TableCell>
                  {/* <TableCell>{aivaPayoutConfig.lastPayout}</TableCell> */}
                  <TableCell>
                    {new Date(aivaPayoutConfig.lastPayout).toLocaleString(
                      "en-IN",
                      {
                        day: "numeric",
                        month: "numeric",
                        year: "numeric",
                      }
                    )}
                  </TableCell>
                  <TableCell>
                    <span
                      onClick={() => handleEditPayout(aivaPayoutConfig.amount)}
                      style={{ cursor: "pointer" }}
                    >
                      <EditPencil />
                    </span>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </section>
      {editPayoutModal && (
        <DialogModal
          openModal={editPayoutModal}
          setOpenModal={setEditPayoutModal}
          heading="Edit A.I.va payout"
          body={editPayoutModalBody}
        />
      )}
    </div>
  );
};

export default AivaPayout;
