import React from "react";
export default function UserIcon() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="user">
        <g id="icon">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.67547 12.8869C5.45687 12.1055 6.51668 11.6665 7.62174 11.6665H14.2884C15.3935 11.6665 16.4533 12.1055 17.2347 12.8869C18.0161 13.6683 18.4551 14.7281 18.4551 15.8332V17.4998C18.4551 17.9601 18.082 18.3332 17.6217 18.3332C17.1615 18.3332 16.7884 17.9601 16.7884 17.4998V15.8332C16.7884 15.1701 16.525 14.5342 16.0562 14.0654C15.5873 13.5966 14.9515 13.3332 14.2884 13.3332H7.62174C6.9587 13.3332 6.32282 13.5966 5.85398 14.0654C5.38514 14.5342 5.12174 15.1701 5.12174 15.8332V17.4998C5.12174 17.9601 4.74865 18.3332 4.28841 18.3332C3.82817 18.3332 3.45508 17.9601 3.45508 17.4998V15.8332C3.45508 14.7281 3.89406 13.6683 4.67547 12.8869Z"
            fill="#ADAAB5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.9551 3.33317C9.57437 3.33317 8.45508 4.45246 8.45508 5.83317C8.45508 7.21388 9.57437 8.33317 10.9551 8.33317C12.3358 8.33317 13.4551 7.21388 13.4551 5.83317C13.4551 4.45246 12.3358 3.33317 10.9551 3.33317ZM6.78841 5.83317C6.78841 3.53198 8.65389 1.6665 10.9551 1.6665C13.2563 1.6665 15.1217 3.53198 15.1217 5.83317C15.1217 8.13436 13.2563 9.99984 10.9551 9.99984C8.65389 9.99984 6.78841 8.13436 6.78841 5.83317Z"
            fill="#ADAAB5"
          />
        </g>
      </g>
    </svg>
  );
}
