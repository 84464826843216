import { HTTP_METHODS } from "../common/constants";
import { makeNetworkCall } from "./network";

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}admin`;

export const fetchEnterpriseList = (query?: string) => {
  let url;
  query
    ? (url = `${baseUrl}/enterprise/list${query}`)
    : (url = `${baseUrl}/enterprise/list`);
  return makeNetworkCall(HTTP_METHODS.GET, url);
};

export const AddEnterprise = (payload: any) => {
  let url = `${baseUrl}/customer/enterprise`;
  return makeNetworkCall(HTTP_METHODS.POST, url, payload);
};

export const fetchEnterpriseDetail = (id: string) => {
  let url = `${baseUrl}/enterprise/profile/${id}`;
  return makeNetworkCall(HTTP_METHODS.GET, url);
};

export type updateEnterpriseProps = {
  companyName?: string;
  location?: string;
  seats?: number;
  callHours?: number;
  isAdd?: boolean;
};
export const updateEnterpriseDetail = (
  id: string,
  payload: updateEnterpriseProps
) => {
  let url = `${baseUrl}/enterprise/profile/${id}`;
  const body = {
    ...payload,
    seats: Number(payload.seats),
    callHours: Number(payload.callHours),
  };
  return makeNetworkCall(HTTP_METHODS.PATCH, url, body);
};

export const fetchEnterpriseMemeberList = (query: string) => {
  let url;
  query
    ? (url = `${baseUrl}/license/customers?${query}`)
    : (url = `${baseUrl}/license/customers`);
  return makeNetworkCall(HTTP_METHODS.GET, url);
};

export const removeEnterpiseUser = (id: string) => {
  let url;
  url = `${baseUrl}/delete/customer/${id}`;
  return makeNetworkCall(HTTP_METHODS.DELETE, url);
};

export const fetchProjectList = (query: string) => {
  let url;
  query
    ? (url = `${baseUrl}/customer/projects?${query}`)
    : (url = `${baseUrl}/customer/projects`);
  return makeNetworkCall(HTTP_METHODS.GET, url);
};

export const inviteMember = (payload: any) => {
  const url = `${baseUrl}/invite`;
  return makeNetworkCall(HTTP_METHODS.POST, url, payload);
}

type makeEnterpiseUserProps = {
  email: string;
  enterpriseProfileId: string;
};
export const makeUserToEnterpiseUser = (payload: makeEnterpiseUserProps)=>{
  const url = `${baseUrl}/customer/role`;
  return makeNetworkCall(HTTP_METHODS.PATCH, url, payload);
}