import { forwardRef } from "react";
import styles from "./button.module.scss";

export type buttonVariantProps =
  | "primary"
  | "primaryDisabled"
  | "light"
  | "lightDisabled"
  | "dark"
  | "darkDisabled"
  | "grey"
  | "greyNoBorder"
  | "transparent"
  | "disabled"
  | "noBorder"
  | "searchIcon"
  | "fullwidth"
  | "blue"
  | "success"
  | "successDisabled"
  | "error";
interface childrenProps {
  type?: "link" | "button" | "submit";
  variant?: buttonVariantProps;
  children?: any;
  className?: string;
  onClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
  [attr: string]: any;
}

const Button = forwardRef((
  { type = "button", variant = "primary", children = "", className = "", onClick, ...attr }: childrenProps,
  ref: any
) => {
  return (
    <>
      {type === "link" && (
        <a onClick={onClick} ref={ref} className={`${styles.button} ${styles[variant]} ${className}`} {...attr}>
          {" "}
          {children}
        </a>
      )}

      {type === "button" && (
        <button onClick={onClick} ref={ref} className={`${styles.button} ${styles[variant]} ${className}`} {...attr}>
          {children}
        </button>
      )}

      {type === "submit" && (
        <input
          //onClick={onClick}
          type={type}
          value={children}
          ref={ref}
          className={`${styles.button} ${styles[variant]} ${className}`}
          {...attr}
        />
      )}
    </>
  );
});

export default Button;
