import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getIndustries } from "../../services/authForms";
import { customerSuspend, fetchCustomerProfile, revokeSupendedCustomer } from "../../services/customerListing";
import { CustomerProfileProviderProps, CustomerProfileState, customerUserProps } from "./type";

const sampleData = {
  id: "",
  email: "",
  title: "",
  name: "",
  avatarUrl: "",
  role: "",
  createdAt: "",
  updatedAt: "",
  deactivatedAt: "",
  industryId: "",
  lineOfBusinessId: "",
  createdByUserId: "",
  updatedByUserId: "",
  activityLog: [],
  lastActivityOn: "",
  lastLoginOn: "",
  averageCallDurationInHours: 0,
};

const CustomerProfileContext = createContext<CustomerProfileState | undefined>(undefined);

// hook for getting context state and function
export const useCustomerProfile = (): CustomerProfileState => {
  const context = useContext(CustomerProfileContext);
  if (!context) {
    throw new Error("CustomerProfile must be used within a CustomerProfileProvider");
  }
  return context;
};

// Context Provide
const CustomerProfileProvider = ({ children }: CustomerProfileProviderProps) => {
  const { customerId = "" } = useParams();
  const [customerInfo, setcustomerInfo] = useState<customerUserProps>(sampleData);
  const [industrieList, setIndustrieList] = useState<any[]>([]);

  const refreshProfile = useCallback(() => {
    customerId &&
      fetchCustomerProfile(customerId)?.then((res: any) => {
        if (res?.data?.status === "success") {
          const resData = res.data.data;
          const customerData = {
            ...resData.userProfile,
            totalRevenue: resData.totalRevenue,
            subscriptionPlan: resData.subscriptionPlan,
            lastActivityOn: resData.lastActivityOn,
            lastLoginOn: resData.lastLoginOn,
          };
          setcustomerInfo(customerData);
        }
      });
  }, [customerId]);

  const suspendCustomer = (onResponse?: (res: any) => void) => {
    customerSuspend(customerId)?.then((res) => {
      const resData = res.data || {};
      if (resData.status === "success") {
        refreshProfile();
      }
      onResponse && onResponse(res);
    });
  };

  const revokedCustomer = (onResponse?: (res: any) => void) => {
    revokeSupendedCustomer(customerId)?.then((res) => {
      const resData = res.data || {};
      if (resData.status === "success") {
        refreshProfile();
      }
      onResponse && onResponse(res);
    });
  };

  useEffect(() => {
    refreshProfile();
  }, [refreshProfile]);

  useEffect(() => {
    getIndustries()?.then((res) => {
      const resData = res?.data;
      if (resData?.status) {
        setIndustrieList(resData.data);
      }
    });
  }, []);

  return (
    <CustomerProfileContext.Provider
      value={{ customerInfo, industrieList, refreshProfile, suspendCustomer, revokedCustomer }}
    >
      {children}
    </CustomerProfileContext.Provider>
  );
};

export default CustomerProfileProvider;
