export default function DashboardIcon() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="dashboard">
        <g id="icon">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.62207 2.49984C2.62207 2.0396 2.99517 1.6665 3.4554 1.6665H9.28874C9.74897 1.6665 10.1221 2.0396 10.1221 2.49984V8.33317C10.1221 8.79341 9.74897 9.1665 9.28874 9.1665H3.4554C2.99517 9.1665 2.62207 8.79341 2.62207 8.33317V2.49984ZM4.28874 3.33317V7.49984H8.4554V3.33317H4.28874Z"
            fill="#ADAAB5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.7887 2.49984C11.7887 2.0396 12.1618 1.6665 12.6221 1.6665H18.4554C18.9156 1.6665 19.2887 2.0396 19.2887 2.49984V8.33317C19.2887 8.79341 18.9156 9.1665 18.4554 9.1665H12.6221C12.1618 9.1665 11.7887 8.79341 11.7887 8.33317V2.49984ZM13.4554 3.33317V7.49984H17.6221V3.33317H13.4554Z"
            fill="#ADAAB5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.7887 11.6665C11.7887 11.2063 12.1618 10.8332 12.6221 10.8332H18.4554C18.9156 10.8332 19.2887 11.2063 19.2887 11.6665V17.4998C19.2887 17.9601 18.9156 18.3332 18.4554 18.3332H12.6221C12.1618 18.3332 11.7887 17.9601 11.7887 17.4998V11.6665ZM13.4554 12.4998V16.6665H17.6221V12.4998H13.4554Z"
            fill="#ADAAB5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.62207 11.6665C2.62207 11.2063 2.99517 10.8332 3.4554 10.8332H9.28874C9.74897 10.8332 10.1221 11.2063 10.1221 11.6665V17.4998C10.1221 17.9601 9.74897 18.3332 9.28874 18.3332H3.4554C2.99517 18.3332 2.62207 17.9601 2.62207 17.4998V11.6665ZM4.28874 12.4998V16.6665H8.4554V12.4998H4.28874Z"
            fill="#ADAAB5"
          />
        </g>
      </g>
    </svg>
  );
}
